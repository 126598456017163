import { Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import context from "../context/context";

const Public = ({ children }) => {
  const { userToken, charCode, setCharCode } = useContext(context);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Para acessar um parâmetro específico, você pode usar get():
  const paramValue = queryParams.get("charcode");

  setCharCode(paramValue);

  return userToken ? (
    children
  ) : (
    <Navigate
      to={`/auth/login/${paramValue || charCode ? paramValue || charCode : ""}`}
    />
  );
  // return children
};

export default Public;
