import React from "react";

function Upload({ color }) {
  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8802 5.98163L23.6002 10.7016M18.8802 23.2883V5.98163V23.2883ZM18.8802 5.98163L14.1602 10.7016L18.8802 5.98163Z"
        stroke={color || "#379C44"}
        stroke-width="2.36"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.1732 30.3683H12.5865C11.0347 30.3683 10.5335 30.361 10.1517 30.3005C7.79545 29.9273 5.94748 28.0793 5.57428 25.7231C5.51382 25.3412 5.50649 24.8401 5.50649 23.2883C5.50649 21.7366 5.51382 21.2355 5.57428 20.8536C5.94748 18.4974 7.79545 16.6493 10.1517 16.2761C10.5335 16.2157 11.0347 16.2083 12.5865 16.2083H12.9798C13.6315 16.2083 14.1598 15.68 14.1598 15.0283C14.1598 14.3766 13.6315 13.8483 12.9798 13.8483H12.5865C11.1249 13.8483 10.394 13.8483 9.78255 13.9452C6.41646 14.4783 3.77648 17.1183 3.24334 20.4843C3.14648 21.0959 3.14648 21.8267 3.14648 23.2883C3.14648 24.75 3.14648 25.4808 3.24334 26.0923C3.77648 29.4583 6.41644 32.0984 9.78255 32.6314C10.394 32.7283 11.1249 32.7283 12.5865 32.7283H25.1732C26.6348 32.7283 27.3656 32.7283 27.9772 32.6314C31.3432 32.0984 33.9832 29.4583 34.5163 26.0923C34.6132 25.4808 34.6132 24.75 34.6132 23.2883C34.6132 21.8267 34.6132 21.0959 34.5163 20.4843C33.9832 17.1183 31.3432 14.4783 27.9772 13.9452C27.3656 13.8483 26.6348 13.8483 25.1732 13.8483H24.7798C24.1282 13.8483 23.5998 14.3766 23.5998 15.0283C23.5998 15.68 24.1282 16.2083 24.7798 16.2083H25.1732C26.725 16.2083 27.2261 16.2157 27.6079 16.2761C29.9641 16.6493 31.8122 18.4974 32.1854 20.8536C32.2458 21.2355 32.2532 21.7366 32.2532 23.2883C32.2532 24.8401 32.2458 25.3412 32.1854 25.7231C31.8122 28.0793 29.9641 29.9273 27.6079 30.3005C27.2261 30.361 26.725 30.3683 25.1732 30.3683Z"
        fill={color || "#379C44"}
      />
    </svg>
  );
}

export default Upload;
