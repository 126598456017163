import React, { useContext } from "react";
import { SearchIcon } from "../../assets/Icons";
import context from "../../context/context";
const SearchBox = ({ customStyles, submit, value, setValue }) => {
  const { colors } = useContext(context);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (typeof submit === "function") {
          submit();
        }
      }}
      className="search-component flex items-center"
      style={customStyles}
    >
      <input
        value={value}
        onChange={({ target }) => {
          setValue(target.value);
        }}
        type="text"
        placeholder="Buscar"
        className=" cleanbtn w-full"
      />
      <div
        className="search-icon flex items-center justify-center"
        onClick={typeof submit === "function" ? submit : null}
      >
        <SearchIcon color={colors.backgroundColor} />
      </div>
    </form>
  );
};

export default SearchBox;
