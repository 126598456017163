import { useContext } from "react";
import context from "../context/context";

export default function Layout({ children }) {
  const { colors } = useContext(context);
  return (
    <>
      {colors.backgroundColor ? (
        <style>
          {`.swiper-button-prev,
          .swiper-button-next {
            background-color: ${colors.backgroundColor};
          }
          .square::before , .square::after{
          background: ${colors.backgroundColor}
          }`}
        </style>
      ) : (
        <style>
          {`.swiper-button-prev,
              .swiper-button-next {
                background-color:  #569a59;
              }`}
        </style>
      )}
      {children}
    </>
  );
}
