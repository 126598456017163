import React from "react";

function ProfileIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2178 5.98734C15.2178 7.77851 13.7658 9.23053 11.9746 9.23053C10.1835 9.23053 8.73145 7.77851 8.73145 5.98734C8.73145 4.19616 10.1835 2.74414 11.9746 2.74414C13.7658 2.74414 15.2178 4.19616 15.2178 5.98734Z"
        stroke="black"
        stroke-width="1.49686"
      />
      <path
        d="M18.2121 16.9642C18.2121 18.0376 17.6043 19.08 16.4795 19.8833C15.3563 20.6857 13.7661 21.2053 11.9752 21.2053C10.1843 21.2053 8.5941 20.6857 7.47083 19.8833C6.34612 19.08 5.73828 18.0376 5.73828 16.9642C5.73828 15.8909 6.34612 14.8485 7.47083 14.0452C8.5941 13.2428 10.1843 12.7231 11.9752 12.7231C13.7661 12.7231 15.3563 13.2428 16.4795 14.0452C17.6043 14.8485 18.2121 15.8909 18.2121 16.9642Z"
        stroke="black"
        stroke-width="1.49686"
      />
    </svg>
  );
}

export default ProfileIcon;
