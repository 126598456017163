import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./styles.scss";


export default function HowItWorks() {
  return (
    <>
      <Header />
      <div className="container">
        <h1>Como funciona o CashBack?</h1>
        <ul>
          <li>
            <span>1- </span>
            Escolha o parceiro online e verifique as regras de utilização de
            cada benefício{" "}
          </li>
          <li>
            <span>2- </span>
            Clique no parceiro desejado, e você será redirecionado para o acesso
            aos produtos.
          </li>
          <li>
            <span>3- </span>Escolha o produto(s) desejado(s) e finalize a compra.
          </li>
          <li>
            <span>4- </span>
            finalizado a compra, em até 2 horas irá aparecer na sua tela de
            perfil na aba pendente o valor que você recebeu de cashback na
            compra realizada.
          </li>
        </ul>

        <h3>
          <span>Importante:</span> Na aba "Disponível" vai estar apenas o valor
          disponível para saque via pix. O valor mínimo para saque é a partir de
          R$ 20,00 e o tempo após a solicitação de resgate irá variar de acordo
          com cada parceiro. No seu extrato você poderá ver o registro da compra
          e o prazo de liberação do valor correspondente da marca que foi
          realizada a compra.
        </h3>
      </div>
      <Footer />
    </>
  );
}
