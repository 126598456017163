import { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ViewIcon } from "../../assets/Icons";
import Loader from "../../components/Loader";
import api from "../../connections/API";
import context from "../../context/context";
import { RxEyeClosed } from "react-icons/rx";
import { MdRemoveRedEye } from "react-icons/md";
import { codeEdit } from "@uiw/react-md-editor";
const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState(null);
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pix, setPix] = useState("");
  const [pixType, setPixType] = useState("CPF");
  const [token, setToken] = useState("");
  const charCode = process.env.REACT_APP_SUPERAPP_TOKEN;
  const { setUserStatus, userToken, setUserToken, setCharCode } =
    useContext(context);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [checkList, setCheckList] = useState(null);
  const [validCPF, setValidCPF] = useState(null);
  const [verifyCode, setVerifyCode] = useState(false);
  const [previousToken, setPreviousToken] = useState("");
  const [code, setCode] = useState("");
  const [id, setId] = useState(null);
  const [logo, setLogo] = useState(null);
  const [color, setColor] = useState(null);
  const [assetsLoading, setAssetsLoading] = useState(true);

  useEffect(() => {
    if (userToken) {
      localStorage.setItem("userToken", userToken);
      localStorage.setItem("userStatus", "true");
      setUserStatus("true");
      navigate("/");
      if (charCode) {
        setCharCode(charCode);
      }
    }
    if (loading) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    }
    if (!loading) {
      document.body.style.overflow = "auto";
    }
  }, [loading]);
  async function getAssets() {
    try {
      setAssetsLoading(true);
      const assets = await api.getAssetsCompany({ code: charCode });
      setLogo(assets.image);
      setId(assets.companyId);
      setColor(assets.backgroundColor);
      setAssetsLoading(false);
    } catch (err) {
      console.log(err);
      setAssetsLoading(false);
    }
  }
  useEffect(() => {
    if (charCode) {
      getAssets();
    }
  }, [charCode]);

  async function sendEmailAndVerifyCode({ token, payload }) {
    try {
      const sendEmail = await api.sendEmailAndVerifyCode({
        token,
        payload,
      });

      return true;
    } catch (err) {
      console.log(err);

      return false;
    }
  }
  async function checkCode(code) {
    setLoading(true);
    const check = await sendEmailAndVerifyCode({
      token: previousToken,
      payload: { code },
    });
    if (!check) {
      setCode("");
      setLoading(false);
      toast.error("Código Incorreto Tente Novamente");
      return;
    }
    document.body.style.overflow = "auto";
    setLoading(false);
    setUserToken(previousToken);
    navigate("/");
  }
  const registerFunction = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      const payload = {
        name,
        cpf,
        password,
      };
      const data = await api.userRegister(payload);
      if (data.token) {
        const update = await api.updateUser({
          token: data.token,
          payload: { pixType, pix },
        });
        setPreviousToken(data.token);
        const sendEmail = await sendEmailAndVerifyCode({
          token: data.token,
          payload: { email },
        });
        if (!sendEmail) {
          toast.success("Conta criada com sucesso!");
          navigate("/auth/login");
          return;
        }

        setVerifyCode(true);
        setValidCPF(null);
      }

      // else setError('Falha ao realizar o login');
    } catch ({ response }) {
      toast.error("Erro ao criar Conta!");
      setError(response.data.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const onChangeCPF = async () => {
      if (/^[0-9]{11}$/gm.test(cpf)) {
        setLoading(true);
        const data = await api.cpfVerify({ cpf });
        setValidCPF(data);
        setLoading(false);
      } else {
        setValidCPF(null);
      }
    };
    onChangeCPF();
  }, [cpf]);

  useEffect(() => {
    if (loading) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    }
    if (!loading) {
      document.body.style.overflow = "auto";
    }
  }, [loading]);

  const validRegister = () => {
    const validEmail = email.length > 3;
    const validPassword = password.length > 3;
    const validPix = pix.length > 3;
    return validEmail && validPassword && validPix;
  };
  return !assetsLoading || !charCode ? (
    <div className="login-page h-screen flex justify-center items-center">
      <form
        className="wrap wrapWidth flex items-center justify-center"
        onSubmit={registerFunction}
      >
        <div className="login-box flex flex-col">
          <Link to="/" className="flex items-center justify-center">
            <img
              src={
                logo
                  ? logo
                  : "https://clubecerto.com.br/cashback/images/logo2.svg"
              }
              className="logo"
              alt="Logo"
            />
          </Link>
          <div className="logo-form flex flex-col">
            <div
              className="box-heading p-3 text-center"
              style={{ backgroundColor: color, borderColor: color }}
            >
              <p className="mt-2">Bem-vindo(a)</p>
            </div>
            {verifyCode ? (
              <></>
            ) : (
              <>
                <div className="input-tag">Insira seu CPF</div>
                <div className="input-box flex items-center">
                  <input
                    name="cpf"
                    type="text"
                    value={cpf}
                    onChange={({ target: { value } }) => {
                      if (value.length <= 11) setCpf(value);
                    }}
                    placeholder="Insera seu cpf"
                    className="txt cleanbtn w-full mt-1"
                  />
                </div>
              </>
            )}
            {validCPF?.check === "CreatePendency" && (
              <>
                <div>Insira seu nome</div>
                <div className="input-box flex items-center">
                  <input
                    name="name"
                    type="text"
                    value={name}
                    onChange={({ target: { value } }) => setName(value)}
                    placeholder="Nome"
                    className="txt cleanbtn w-full mt-1"
                  />
                </div>
                <div>Insira seu e-mail</div>
                <div className="input-box flex items-center">
                  <input
                    name="email"
                    type="email"
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                    placeholder="Email"
                    className="txt cleanbtn w-full mt-1"
                  />
                </div>
                <div>Tipo da chave pix</div>
                <div style={{ position: "relative" }}>
                  <select
                    className="input-box flex items-center select"
                    style={{ padding: "8px 0px" }}
                    value={pixType}
                    onChange={({ target: { value } }) => setPixType(value)}
                  >
                    <option value="CPF">CPF</option>
                    <option value="CNPJ">CNPJ</option>
                    <option value="EMAIL">EMAIL</option>
                    <option value="CELULAR">CELULAR</option>
                    <option value="CHAVE">CHAVE ALEATÓRIA</option>
                  </select>
                </div>
                <div>Chave Pix</div>
                <div className="input-box flex items-center">
                  <input
                    name="pix"
                    type="text"
                    value={pix}
                    onChange={({ target: { value } }) => setPix(value)}
                    placeholder="Chave Pix"
                    className="txt cleanbtn w-full mt-1"
                  />
                </div>
                <div>Senha</div>
                <div className="input-box flex items-center">
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={({ target: { value } }) => setPassword(value)}
                    placeholder="Senha"
                    className="txt cleanbtn w-full mt-1"
                  />
                  <div
                    className="icon flex items-center justify-center cursor-pointer p-1"
                    onClick={(e) => setShowPassword(!showPassword)}
                    style={{ paddingRight: 10 }}
                  >
                    {showPassword ? (
                      <MdRemoveRedEye size={25} />
                    ) : (
                      <RxEyeClosed size={20} />
                    )}
                  </div>
                </div>

                <div className="mb-4">
                  <input
                    type="submit"
                    disabled={!validRegister()}
                    value="Cadastrar"
                    className="bg-themeColor w-full p-4 rounded text-white uppercase font-semibold cursor-pointer"
                  />
                </div>
              </>
            )}
            {validCPF?.check === "NotFound" && (
              <span className="errorMessage">
                CPF não cadastrado na base de dados
              </span>
            )}
            {validCPF?.check === "Valid" && (
              <span className="errorMessage">
                Cashback ja cadastrado neste CPF
              </span>
            )}
            {verifyCode && (
              <>
                <div className="input-tag">
                  Insira o código enviado em seu e-mail
                </div>
                <div className="input-box flex items-center">
                  <input
                    name="cpf"
                    type="text"
                    value={code}
                    maxLength={6}
                    onChange={({ target: { value } }) => {
                      if (value.length <= 6) setCode(value);
                      if (value.length === 6) checkCode(value);
                    }}
                    placeholder="Código de 6 dígitos"
                    className="txt cleanbtn w-full mt-1"
                  />
                </div>
              </>
            )}
            {error && <span className="errorMessage">{error}</span>}
          </div>
        </div>
      </form>
      {loading && <Loader />}
    </div>
  ) : (
    <Loader heavy={true} />
  );
};

export default Login;
