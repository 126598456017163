import { useContext } from "react";
import context from "../../context/context";
import style from "./style.scss";
import jwtDecode from "jwt-decode";
export default function FaqModal({ setOpen, faqType }) {
  const { userToken, colors } = useContext(context);
  return (
    <div className="containerModal">
      <img
        src={
          !jwtDecode(userToken).selectedCompany
            ? "https://clubecerto.com.br/cashback/images/logo2.svg"
            : jwtDecode(userToken).selectedCompany.companiesImage.image
        }
        className="logo2"
      />

      {faqType === "p" ? (
        <h2>O que é saldo pendente?</h2>
      ) : (
        <h2>O que é saldo disponível?</h2>
      )}

      <div style={{ background: colors.backgroundColor }}>
        <h5>
          {faqType === "p" ? (
            <h3>
              É o saldo que ficará aguardando
              <br />
              confirmação de compra. Este
              <br />
              aparecerá em até 2 h após a<br />
              compra realizada.
            </h3>
          ) : (
            <h3>
              É o saldo que você pode solicitar o saque, caso
              <br />
              este seja maior que R$ 20,00 (vinte reais).
              <br />
              O valor será creditado mediante a solicitação
              <br />
              em até 48 h na sua conta atravês do PIX cadastrado.
              <br />
            </h3>
          )}
        </h5>
      </div>

      <button
        onClick={() => {
          setOpen(false);
        }}
        style={{ background: colors.backgroundColor }}
      >
        Entendido
      </button>
    </div>
  );
}
