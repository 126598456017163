import React from "react";

function SendIcon() {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.42362 4.94563L14.4839 1.92555C18.5482 0.570748 20.7639 2.78641 19.4091 6.85082L16.3891 15.911C14.3568 22.0076 11.0333 22.0006 9.00815 15.911L8.40136 14.0765C8.24611 13.6107 7.731 13.0956 7.26529 12.9404L5.42361 12.3264C-0.665944 10.3013 -0.665943 6.97077 5.42362 4.94563Z"
        stroke="#1B1B1B"
        stroke-width="1.49686"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.03418 13.3002L11.8446 9.48975"
        stroke="#1B1B1B"
        stroke-width="1.49686"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SendIcon;
