import jwtDecode from "jwt-decode";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import api from "../connections/API";
import { AxiosProvider } from "../connections/config";
import context from "./context";

function Provider({ children }) {
  const customerToken = localStorage.getItem("userToken");
  const localUserStatus = localStorage.getItem("userStatus");

  const [userToken, setUserToken] = useState(customerToken);
  const [success, setSuccess] = useState("");
  const [searchBar, setSearchBar] = useState(false);
  const [userStatus, setUserStatus] = useState(
    localUserStatus ? localUserStatus : "false"
  );
  const [colors, setColors] = useState({});
  const [extracts, setExtracts] = useState([]);
  const [loading, setLoading] = useState(null);
  const [approved, setApproved] = useState(0);
  const [pending, setPending] = useState(0);
  const [cashbackHistory, setCashbackHistory] = useState([]);
  const [user, setUser] = useState("");
  const [charCode, setCharCode] = useState(
    process.env.REACT_APP_SUPERAPP_TOKEN
  );
  const loadExtract = async () => {
    if (loading === null) setLoading(true);
    const extract = await api.getExtractsAndResume({ token: userToken });
    setExtracts(extract.extracts);
    setApproved(extract.approved);
    setPending(extract.pending);
    setLoading(false);
  };

  const loadCashbackHistory = async () => {
    if (loading === null || loading === false) setLoading(true);
    const data = await api.getCashbackHistory({ token: userToken });
    setCashbackHistory(data);
    setLoading(false);
  };

  const postRescue = async () => {
    if (loading === null || loading === false) setLoading(true);
    try {
      const data = await api.rescueAllValue({ token: userToken });
      setLoading(false);
      return true;
    } catch (err) {
      console.log(err);
      toast.error(err?.data?.title || "Error");
      return false;
    }
  };
  const [size, setSize] = useState(window.innerWidth);
  const [companiesListLoading, setCompaniesListLoading] = useState(false);
  const [companiesListData, setCompaniesListData] = useState([]);
  const [viewPromotionsListData, setViewPromotionsListData] = useState([]);

  const loadCompany = async () => {
    setCompaniesListLoading(true);
    const [data, data2] = await Promise.all([
      api.getCompaniesList({ token: userToken }),
      api.viewPromotions({ id: null }),
    ]);
    setViewPromotionsListData(data2);
    setCompaniesListData(data);
    setCompaniesListLoading(false);
  };

  const logout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userStatus");
    setUserToken(null);
    setUserStatus("false");
  };
  const clearMessage = () => {
    setSuccess("");
  };
  const toggleSearchBar = () => {
    setSearchBar((bool) => !bool);
  };

  function handleResize() {
    setSize(window.innerWidth);
  }
  async function getUser() {
    setLoading(true);
    const data = await api.getUserData({ token: userToken });
    console.log(data);
    setUser(data);
    setLoading(false);
  }
  useEffect(() => {
    if (!userToken) {
      return;
    }

    getUser();
    loadCompany();
    window.addEventListener("resize", handleResize);
    if (userStatus === "true") loadExtract();
    if (userToken && jwtDecode(userToken).selectedCompany) {
      let newColors = {
        primaryColor:
          jwtDecode(userToken)?.selectedCompany.companiesColor.primaryColor,
        backgroundColor:
          jwtDecode(userToken)?.selectedCompany.companiesColor.backgroundColor,
      };
      setColors(newColors);
    }
  }, [userToken]);
  useEffect(() => {
    if (loading) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    }
    if (!loading) {
      document.body.style.overflow = "auto";
    }
  }, [loading]);
  AxiosProvider.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );
  const value = useMemo(
    () => ({
      loading,
      companiesListLoading,
      companiesListData,
      viewPromotionsListData,
      extracts,
      approved,
      pending,
      loadExtract,
      success,
      setSuccess,
      userToken,
      setUserToken,
      searchBar,
      setSearchBar,
      userStatus,
      setUserStatus,
      logout,
      clearMessage,
      toggleSearchBar,
      size,
      loadCashbackHistory,
      cashbackHistory,
      postRescue,
      setCompaniesListData,
      setViewPromotionsListData,
      loadCompany,
      setLoading,
      user,
      setUser,
      colors,
      setCharCode,
      charCode,
    }),
    [
      loading,
      userToken,
      success,
      searchBar,
      userStatus,
      size,
      companiesListLoading,
      companiesListData,
      viewPromotionsListData,
      extracts,
      approved,
      pending,
      cashbackHistory,
      user,
      colors,
      charCode,
    ]
  );

  return <context.Provider value={value}>{children}</context.Provider>;
}

Provider.propTypes = { children: PropTypes.node.isRequired };

export default Provider;
