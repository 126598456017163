import jwtDecode from "jwt-decode";
import { useContext } from "react";
import { useState } from "react";
import ListIcon from "../../assets/Icons/ListIcon";
import PixIcon from "../../assets/Icons/PixIcon";
import ProfileIcon from "../../assets/Icons/ProfileIcon";
import SendIcon from "../../assets/Icons/SendIcon";
import api from "../../connections/API";
import context from "../../context/context";
import style from "./style.scss";

export function Settings({ user, setUser, updateUser }) {
  const { userToken, setUserToken, colors } = useContext(context);

  const [pixType, setPixType] = useState(user?.pixType);
  const [pix, setPix] = useState("");
  const [update, setUpdate] = useState(false);

  const updatePix = async () => {
    const data = await api.updateUser({
      token: userToken,
      payload: { pix, pixType },
    });
    setUser(data);
    setUpdate(false);
  };

  return (
    <form className="formSettings">
      <h1>Informações Básicas</h1>
      <div>
        <h3>Nome</h3>
        <div style={{ position: "relative" }}>
          <ProfileIcon />
          <input type={"text"} value={user?.name} />
        </div>
      </div>
      <div>
        <h3>e-mail</h3>
        <div style={{ position: "relative" }}>
          <SendIcon />
          <input type={"text"} value={user?.email} />
        </div>
      </div>
      <div>
        <h3>Cpf</h3>
        <div style={{ position: "relative" }}>
          <ListIcon />
          <input type={"text"} value={user?.cpf} />
        </div>
      </div>
      <h1>Para Receber Cashback</h1>
      <button
        onClick={() => setUpdate(!update)}
        type="button"
        className="defaultButton"
        style={{ backgroundColor: colors.backgroundColor || "#569A59" }}
      >
        Atualizar
      </button>
      {update ? (
        <>
          <div>
            <h3>Tipo de Chave</h3>
            <div style={{ position: "relative" }}>
              <PixIcon color={colors.backgroundColor} />
              <select
                defaultValue={user?.pixType}
                style={{ paddingLeft: 40 }}
                value={pixType}
                onChange={({ target: { value } }) => setPixType(value)}
              >
                <option value="CPF">CPF</option>
                <option value="CNPJ">CNPJ</option>
                <option value="EMAIL">EMAIL</option>
                <option value="CELULAR">CELULAR</option>
                <option value="CPF">CPF</option>
                <option value="CHAVE">CHAVE ALEATÓRIA</option>
              </select>
            </div>
          </div>
          <div>
            <h3>Chave</h3>
            <div style={{ position: "relative" }}>
              <PixIcon color={colors.backgroundColor} />
              <input
                style={{ paddingLeft: 40 }}
                type={"text"}
                value={pix}
                onChange={({ target: { value } }) => setPix(value)}
              />
            </div>
          </div>
          <button type="button" onClick={updatePix} className="defaultButton">
            Salvar
          </button>
        </>
      ) : (
        <>
          <div>
            <h3>Tipo de Chave</h3>
            <div style={{ position: "relative" }}>
              <PixIcon color={colors.backgroundColor} />
              <input type={"text"} value={pixType} />
            </div>
          </div>
          <div>
            <h3>Chave</h3>
            <div style={{ position: "relative" }}>
              <PixIcon color={colors.backgroundColor} />
              <input type={"text"} value={user?.pix} />
            </div>
          </div>
        </>
      )}
    </form>
  );
}
