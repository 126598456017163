import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Private from "./Private";
import Public from "./Public";

import Extract from "../Pages/Extract";
import Login from "../Pages/Login";
import LoginDirect from "../Pages/LoginDirect";
import Registration from "../Pages/Registration";

import CompanyDetail from "../Pages/CompanyDetail";
import Home from "../Pages/Home";
import HowItWorks from "../Pages/HowItWorks";

const Routing = () => {
  return (
    <HashRouter>
      <Routes>
        <Route
          path="/:charCode?"
          element={
            <Public>
              <Home />
            </Public>
          }
        />
        <Route path="company">
          <Route
            path="detail/:id"
            element={
              <Public>
                <CompanyDetail />
              </Public>
            }
          />
        </Route>
        <Route path="auth">
          <Route path="login/:charCode?" element={<Login />} />
          <Route path="loginDirect/:charCode?" element={<LoginDirect />} />
          <Route path="register" element={<Registration />} />
        </Route>
        <Route path="/howitworks" element={<HowItWorks />} />
      </Routes>
      <div className="flex flex-col">
        <Routes>
          <Route path="dashboard">
            <Route
              path="home"
              element={
                <Private>
                  <Extract />
                </Private>
              }
            />
          </Route>
        </Routes>
      </div>
    </HashRouter>
  );
};

export default Routing;
