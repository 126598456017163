import React from "react";

function PixIcon({ color }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6041 11.7773L25.1498 8.31753C25.1498 8.31753 25.0079 8.36493 24.9132 8.36493H23.3517C22.5473 8.36493 21.7429 8.69668 21.1751 9.2654L16.6798 13.7678C16.2539 14.1943 15.6861 14.3839 15.1656 14.3839C14.6451 14.3839 14.0773 14.1943 13.6514 13.7678L9.15615 9.2654C8.58833 8.69668 7.78391 8.36493 6.9795 8.36493H5.03943C5.03943 8.36493 4.89748 8.36493 4.80284 8.36493L1.34858 11.8246C-0.449527 13.6256 -0.449527 16.5166 1.34858 18.2701L4.80284 21.7299C4.80284 21.7299 4.9448 21.7299 5.03943 21.7299H6.9795C7.78391 21.7299 8.58833 21.3981 9.15615 20.8294L13.6514 16.327C14.4558 15.5213 15.8754 15.5213 16.7271 16.327L21.2224 20.8294C21.7902 21.3981 22.5946 21.7299 23.3991 21.7299H24.9606C24.9606 21.7299 25.1498 21.7299 25.1972 21.7772L28.6514 18.3175C30.4495 16.5166 30.4495 13.6256 28.6514 11.872L28.6041 11.7773ZM23.3517 22.9621C22.1688 22.9621 21.0804 22.4881 20.2287 21.6825L15.7334 17.1801C15.4022 16.8483 14.8817 16.8483 14.5505 17.1801L10.0552 21.6825C9.20347 22.5355 8.11514 22.9621 6.93218 22.9621H6.03312L11.7114 28.6493C13.5095 30.4502 16.3959 30.4502 18.1467 28.6493L23.8722 22.9147H23.3517V22.9621ZM6.88486 7.03791C8.06782 7.03791 9.15615 7.51185 10.0079 8.31753L14.5032 12.8199C14.8344 13.1517 15.3549 13.1517 15.6861 12.8199L20.1814 8.31753C21.0331 7.46445 22.1215 7.03791 23.3044 7.03791H23.8249L18.1467 1.35071C16.3486 -0.450237 13.4621 -0.450237 11.7114 1.35071L5.9858 7.03791H6.88486Z"
        fill={color || "#569A59"}
      />
    </svg>
  );
}

export default PixIcon;
