import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import context from "../../context/context";
import jwtDecode from "jwt-decode";

const Footer = () => {
  const navigate = useNavigate();
  const { userToken, colors } = useContext(context);
  return (
    <div className="footer-comp flex flex-col">
      <div className="wrapWidth wrap flex jc aic">
        <div className="left flex">
          <div className="footer-links flex flex-col">
            <div className="link-tag">Dúvidas e Informações</div>
            <div
              className="link-item"
              onClick={() => {
                navigate("/howitworks");
              }}
              style={{
                color: colors.backgroundColor
                  ? colors.backgroundColor
                  : "#569A59",
              }}
            >
              Como funciona o Cashback
            </div>
          </div>
        </div>
        <div className="center flex flex-col">
          <div className="footer-links flex flex-col">
            {/* <div className="link-tag">Sobre nós</div>
            <div className="link-item">Quem somos</div> */}
            {/* <div className="link-item">Parceiros</div>
            <div className="link-item">Missão, Visão e Valores</div>
            <div className="link-item">Relacionar com investidor</div> */}
          </div>
        </div>
        <div className="right flex">
          <div className="logos flex items-center">
            {/* <img
              src="https://clubecerto.com.br/cashback/images/logo2.svg"
              className="logo2"
              alt="Logo"
            /> */}
            {jwtDecode(userToken).selectedCompany && (
              <div className="separator"></div>
            )}
            <img
              style={{ width: 180 }}
              src={
                jwtDecode(userToken).selectedCompany
                  ? jwtDecode(userToken).selectedCompany.companiesImage.image
                  : ""
              }
              className="logo3"
              alt="Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
