import { useContext, useEffect, useState } from "react";
import { MdRemoveRedEye } from "react-icons/md";
import { RxEyeClosed } from "react-icons/rx";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import api from "../../connections/API";
import context from "../../context/context";

const Login = () => {
  const navigate = useNavigate();
  const { setUserStatus, userToken, setUserToken, setCharCode } =
    useContext(context);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [savePassword, setSavePassword] = useState(false);
  const [token, setToken] = useState(null);
  const [assetsLoading, setAssetsLoading] = useState(true);
  const [id, setId] = useState(null);
  const [logo, setLogo] = useState(null);
  const [color, setColor] = useState(null);
  const setCodeWithMaxChar = (value) => {
    if (value.length <= 6) setCode(value);
  };

  const getCharCodeFromHash = () => {
    const hash = window.location.hash;
    const parts = hash.split("/");
    const code = parts[parts.length - 1]
      ? parts[parts.length - 1]
      : process.env.REACT_APP_SUPERAPP_TOKEN;

    return code;
  };

  const charCode = getCharCodeFromHash();

  const redirects = {
    "www.cashback.akimaissaude.com.br": {
      slug: "01cC3K995F",
    },
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const host = window.location.host;
      const redirectKeys = Object.keys(redirects);

      const foundHost = redirectKeys.find((key) => host.includes(key));
      if (foundHost) {
        const { slug } = redirects[foundHost];
        navigate(`/auth/login/${slug}`);
      }
    }
  }, []);

  useEffect(() => {
    if (charCode) {
      localStorage.setItem("charCode", charCode);
      setCharCode(charCode);
    }
  }, [charCode, setCharCode]);

  const loginFunction = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    if (!email || !password) {
      setLoading(false);
      return;
    }
    try {
      const data = await api.authLogin({
        login: email.includes("@")
          ? email.replace(" ", "")
          : email.replace(/\W|\D/gm, ""),
        password,
        id,
      });
      if (data.token) setUserToken(data.token);
      else setError("Falha ao realizar o login");
    } catch ({ response }) {
      setEmail("");
      setPassword("");
      toast.error(response.data.title);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userToken) {
      localStorage.setItem("userToken", userToken);
      localStorage.setItem("userStatus", "true");
      setUserStatus("true");
      navigate("/");
      if (charCode) {
        setCharCode(charCode);
      }
    }
    if (loading) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    }
    if (!loading) {
      document.body.style.overflow = "auto";
    }
  }, [loading]);
  async function getAssets() {
    try {
      setAssetsLoading(true);
      const assets = await api.getAssetsCompany({ code: charCode });
      setLogo(assets.image);
      setId(assets.companyId);
      setColor(assets.backgroundColor);
      setAssetsLoading(false);
    } catch (err) {
      console.log(err);
      setAssetsLoading(false);
    }
  }
  useEffect(() => {
    if (charCode) {
      getAssets();
    }
  }, [charCode]);
  const sendEmail = async () => {
    setError(null);
    setLoading(true);
    try {
      const data = await api.forgotPassword({ login: email });
      setVerifyEmail(true);
    } catch ({ response }) {
      setError(response.data.error);
    }
    setLoading(false);
  };

  const sendCode = async () => {
    setError(null);
    setLoading(true);
    try {
      const data = await api.forgotPassword({ login: email, code });
      if (data.token) {
        setToken(data.token);
        setSavePassword(true);
        setVerifyEmail(false);
      } else {
        setError("Falha ao realizar o login");
      }
    } catch ({ response }) {
      setError(response.data.error);
    }
    setLoading(false);
  };

  const saveNewPassword = async () => {
    if (newPassword !== newPassword2) {
      setError("Senhas não coincidem.");
    } else {
      setLoading(true);
      try {
        const data = await api.updateUser({
          token,
          payload: { password: newPassword },
        });
        if (data) {
          toast.success("Senha alterada com sucesso!");
          navigate("/");
        } else setError("Falha ao atualizar a senha");
      } catch ({ response }) {
        console.log(response);
        setError(response.data.error);
      }
      setLoading(false);
    }
  };

  let [searchParams] = useSearchParams();

  const getQueryParam = (param) => {
    return searchParams.get(param);
  };

  const teste = getQueryParam("teste");

  return !assetsLoading || !charCode ? (
    <div
      className="login-page h-screen flex justify-center items-center"
      style={{ overflow: loading ? "hidden" : "auto" }}
    >
      <form
        className="wrap wrapWidth flex items-center justify-center"
        onSubmit={loginFunction}
      >
        <div className="login-box flex flex-col">
          <Link to="#" className="flex items-center justify-center">
            <img src={logo} className="logo" alt="Logo" />
          </Link>
          <div className="logo-form flex flex-col">
            <div
              className="box-heading p-3 text-center"
              style={{ backgroundColor: color, borderColor: color }}
            >
              <p className="mt-2">Bem-vindo(a)</p>
            </div>
            {!savePassword && (
              <>
                <div className="input-tag">
                  {verifyEmail
                    ? "Insira o codigo enviado por e-mail"
                    : "Insira seu e-mail ou CPF"}
                </div>
                <div className="input-box flex items-center">
                  <input
                    name={verifyEmail ? "code" : "email"}
                    type="text"
                    value={verifyEmail ? code : email}
                    onChange={({ target: { value } }) => {
                      verifyEmail ? setCodeWithMaxChar(value) : setEmail(value);
                    }}
                    placeholder="Inserir dados e entrar"
                    className="txt cleanbtn w-full mt-1"
                  />
                </div>
              </>
            )}
            {savePassword && (
              <>
                <div className="input-tag">{"Insira sua nova senha"}</div>
                <div className="input-box flex items-center">
                  <input
                    name="password"
                    type="password"
                    value={newPassword}
                    onChange={({ target: { value } }) => setNewPassword(value)}
                    placeholder="Inserir Senha"
                    className="txt cleanbtn w-full mt-1"
                  />
                </div>
                <div className="input-box flex items-center">
                  <input
                    name="password2"
                    type="password"
                    value={newPassword2}
                    onChange={({ target: { value } }) => setNewPassword2(value)}
                    placeholder="Inserir dados e entrar"
                    className="txt cleanbtn w-full mt-1"
                  />
                </div>
              </>
            )}
            {forgotPassword ? (
              <>
                <button
                  type="button"
                  className="bg-themeColor w-full p-4 rounded text-white uppercase font-semibold cursor-pointer"
                  style={{ backgroundColor: color, borderColor: color }}
                  onClick={() => {
                    if (verifyEmail) sendCode();
                    else if (savePassword) saveNewPassword();
                    else sendEmail();
                  }}
                >
                  {verifyEmail && "Enviar codigo"}
                  {savePassword && "Atualizar senha"}
                  {!verifyEmail && !savePassword && "Enviar Email"}
                </button>
                {error && <span className="errorMessage">{error}</span>}
              </>
            ) : (
              <>
                <div className="input-box flex items-center">
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={({ target: { value } }) => setPassword(value)}
                    placeholder="Inserir Senha"
                    className="txt cleanbtn w-full mt-1"
                  />
                  <div
                    className="icon flex items-center justify-center cursor-pointer"
                    style={{ paddingRight: 10 }}
                    onClick={(e) => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <MdRemoveRedEye size={25} />
                    ) : (
                      <RxEyeClosed size={20} />
                    )}
                  </div>
                </div>
                {error && <span className="errorMessage">{error}</span>}

                <div
                  onClick={() => {
                    navigate("/auth/register");
                  }}
                  className="new-use w-full text-center"
                  style={{
                    textDecoration: "underline",
                    color: "#606060",
                    fontWeight: 600,
                    marginBottom: 10,
                    fontSize: 14,
                  }}
                >
                  Primeiro acesso com Cashback?
                </div>
                <div className="mb-4">
                  <input
                    type="submit"
                    value={loading ? "Loading..." : "Entrar"}
                    style={{ backgroundColor: color, borderColor: color }}
                    className="bg-themeColor w-full p-4 rounded text-white uppercase font-semibold cursor-pointer"
                  />
                </div>
                <button
                  type="button"
                  className="desc1"
                  style={{
                    textDecoration: "underline",
                    color: "#606060",
                  }}
                  onClick={() => {
                    setForgotPassword(true);
                  }}
                >
                  Esqueceu a senha?
                </button>
              </>
            )}
          </div>
        </div>
      </form>
      {loading && <Loader />}
    </div>
  ) : (
    <Loader heavy={true} />
  );
};

export default Login;
