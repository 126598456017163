import { AxiosProvider } from "./config";

class API {
  authLogin = async (payload) => {
    console.log(payload);
    let URL;
    if (payload.id) {
      URL = `https://node.clubecerto.com.br/superapp/user/login/direct/${payload.id}`;
    } else {
      URL = "https://node.clubecerto.com.br/superapp/user/login/cashback";
    }
    const { data } = await AxiosProvider.post(URL, payload);
    return data;
  };

  userRegister = async (payload) => {
    const { data } = await AxiosProvider.post(
      "https://node.clubecerto.com.br/superapp/user",
      payload
    );
    return data;
  };

  updateUser = async ({ token, payload }) => {
    const { data } = await AxiosProvider.patch(
      "https://node.clubecerto.com.br/superapp/user",
      payload,
      { headers: { Authorization: token } }
    );
    return data;
  };

  sendEmailAndVerifyCode = async ({ token, payload }) => {
    const { data } = await AxiosProvider.patch(
      "https://node.clubecerto.com.br/superapp/user/email",
      payload,
      { headers: { Authorization: token } }
    );
    return data;
  };

  getCompaniesList = async ({ token, name, page }) => {
    const { data } = await AxiosProvider.get(
      `https://node.clubecerto.com.br/superapp/cashbackWeb/establishment/?limit=10&${
        page ? "page=" + page + "&" : "page=0&"
      }${name ? "name=" + name : ""}`,
      { headers: { Authorization: token } }
    );
    return data;
  };

  companiesDetail = async ({ id, token }) => {
    const { data } = await AxiosProvider.get(
      `https://node.clubecerto.com.br/superapp/cashbackWeb/establishment/${id}`,
      { headers: { Authorization: token } }
    );
    return data;
  };

  getCompanyLogo = async ({ id }) => {
    const { data } = await AxiosProvider.get(
      `https://clubecerto.com.br/ws/api/2/extensao/brand/${id}.png`
    );
    return data;
  };
  getCashbackHistory = async ({ token }) => {
    const { data } = await AxiosProvider.get(
      "https://node.clubecerto.com.br/superapp/cashbackWeb/withdrawalReport?limit=20&page=0",
      { headers: { Authorization: token } }
    );
    return data;
  };
  cpfVerify = async ({ cpf }) => {
    const { data } = await AxiosProvider.post(
      `https://node.clubecerto.com.br/superapp/user/verify`,
      {
        cpf,
      }
    );
    return data;
  };

  getExtractsAndResume = async ({ token }) => {
    const { data } = await AxiosProvider.get(
      "https://node.clubecerto.com.br/superapp/cashbackWeb/summaryAndExtract?limit=20&page=0",
      { headers: { Authorization: token } }
    );
    return data;
  };
  forgotPassword = async (payload) => {
    const { data } = await AxiosProvider.post(
      "https://node.clubecerto.com.br/superapp/user/recovery",
      payload
    );
    return data;
  };
  viewPromotions = async ({ id }) => {
    const { data } = await AxiosProvider.get(
      `https://clubecerto.com.br/ws/api/2/extensao/all_promotions.php${
        id ? "?id=" + id : ""
      }`
    );
    return data;
  };
  rescueAllValue = async ({ token }) => {
    const { data } = await AxiosProvider.post(
      "https://node.clubecerto.com.br/superapp/cashbackWeb/withdrawal",
      {},
      { headers: { Authorization: token } }
    );
    return data;
  };
  getUserData = async ({ token }) => {
    const { data } = await AxiosProvider.get(
      "https://node.clubecerto.com.br/superapp/user",
      { headers: { Authorization: token } }
    );
    return data;
  };
  getAssetsCompany = async () => {
    let code = localStorage.getItem("charCode");
    if (!code) {
      code = process.env.REACT_APP_SUPERAPP_TOKEN;
    }
    const { data } = await AxiosProvider.get(
      `https://node.clubecerto.com.br/superapp/locations/company/${code}`
    );
    return data;
  };
}

const api = new API();

export default api;
