import React from "react";

function CartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 51 51"
    >
      <path
        stroke="#fff"
        strokeWidth="3.122"
        d="M19.394 38.378a3.122 3.122 0 110 6.244 3.122 3.122 0 010-6.244zM33.964 38.378a3.122 3.122 0 110 6.244 3.122 3.122 0 010-6.244zM12.63 25.89c0-2.988.003-5.072.214-6.644.205-1.528.58-2.336 1.157-2.912.576-.576 1.385-.952 2.912-1.157 1.572-.212 3.656-.215 6.644-.215h6.072c3.47 0 5.904.004 7.713.26 1.766.251 2.649.708 3.231 1.396.583.688.889 1.634.846 3.417-.044 1.827-.441 4.229-1.011 7.651-.422 2.531-.718 4.287-1.11 5.603-.379 1.274-.798 1.948-1.352 2.418-.555.47-1.289.772-2.608.937-1.362.17-3.143.173-5.71.173h-6.071c-2.988 0-5.072-.003-6.644-.215-1.527-.205-2.336-.58-2.912-1.157-.576-.576-.952-1.384-1.157-2.912-.211-1.572-.215-3.656-.215-6.643z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="3.122"
        d="M12.63 24.849v-13.53c0-2.299-1.865-4.163-4.164-4.163H5.865"
      ></path>
    </svg>
  );
}

export default CartIcon;
