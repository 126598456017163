import React from "react";

function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2.36"
        d="M15.34 25.567h7.08"
      ></path>
      <path
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.36"
        d="M3.147 21.575c0-8.86 1.007-8.241 6.432-13.063 2.373-1.832 6.066-5.365 9.255-5.365 3.188 0 6.954 3.516 9.349 5.365 5.424 4.822 6.43 4.204 6.43 13.063 0 13.038-3.215 13.038-15.733 13.038s-15.733 0-15.733-13.038z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default HomeIcon;
