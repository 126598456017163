import jwtDecode from "jwt-decode";
import moment from "moment";
import { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import context from "../../context/context";
import { Navigation } from "swiper";

export function SwiperPromotions({
  promotions,
  name,
  setSelectedPromotion,
  setOpen,
}) {
  const { colors, userToken } = useContext(context);
  const ChangeDataFormat = (date) => {
    let newDate = moment(date, "YYYY-MM-DD");
    let finalDate = newDate.format("DD-MM-YYYY");
    return finalDate;
  };
  return (
    <div className="wrap3 wrapWidth flex flex-col">
      <div className="sec-meta flex flex-col">
        <div
          className="discount-tag"
          style={
            colors.backgroundColor
              ? {
                  color: colors.backgroundColor,
                }
              : null
          }
        >
          As melhores ofertas {name || ""} estão aqui!
        </div>
        <div className="discount-desc">
          Use os cupons de desconto e ainda ganhe cashback!
        </div>
      </div>
      <div className="deals-swiper">
        <Swiper
          slidesPerView={4}
          spaceBetween={30}
          slidesPerGroup={1}
          loop={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: false,
          }}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            // when window width is >= 1024px
            1024: {
              width: 1024,
              slidesPerView: 3.3,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 2.3,
            },
            // when window width is >= 480px
            480: {
              width: 480,
              slidesPerView: 1.1,
            },
            300: {
              width: 300,
              slidesPerView: 1,
            },
          }}
          className="mySwiper"
          style={{ minHeight: 600 }}
        >
          {promotions?.map((item, index) => (
            <SwiperSlide>
              <div className="deal-card flex flex-col">
                <div className="deal-action flex items-center justify-center">
                  <div
                    className="btn button"
                    style={{
                      backgroundColor: colors.backgroundColor
                        ? colors.backgroundColor
                        : "#569A59",

                      borderColor: colors.backgroundColor
                        ? colors.backgroundColor
                        : "#569A59",
                    }}
                  >
                    <p className="mt-1">
                      {item.type == "promotion" ? "PROMOÇÃO" : item.type}
                    </p>
                  </div>
                </div>
                <div className="card-top-part flex flex-col">
                  <div className="company-logo flex items-center justify-center">
                    <img
                      src={
                        item.estabelecimento
                          ? `https://clubecerto.com.br/brand/${item.estabelecimento}.png`
                          : "https://clubecerto.com.br/cashback/images/logo4.svg"
                      }
                      className="logo-img"
                    />
                  </div>
                  <div className="company-info flex flex-col">
                    <div className="c-name">{item.name ? item.name : "-"}</div>
                    {/* <div className="c-desc">Descrição</div> */}
                  </div>
                  <div className="c-separator"></div>
                  <div className="price-desc flex flex-col items-start">
                    <div
                      className="flex items-center justify-center"
                      style={{ width: "100%", marginBottom: 8 }}
                    >
                      <img
                        src={
                          jwtDecode(userToken).selectedCompany
                            ? jwtDecode(userToken).selectedCompany
                                .companiesImage.image
                            : "https://clubecerto.com.br/cashback/images/logo2.svg"
                        }
                        className="icon-img"
                        style={{ width: 70 }}
                      />
                    </div>
                    <div className="price-dec" style={{ minHeight: 100 }}>
                      {item.title}
                    </div>
                  </div>
                </div>
                <div className="card-btm-part flex flex-col">
                  <div className="vail-sec flex items-center justify-center">
                    <div className="valid-tag">
                      Válido até: <span> {ChangeDataFormat(item.endDate)}</span>
                    </div>
                  </div>
                  {item.type == "promotion" ? (
                    <a
                      href={item.urlTracking}
                      target="_blank"
                      className="discount-code-sec flex items-center justify-center cursor-pointer"
                      style={{
                        backgroundColor: colors.backgroundColor
                          ? colors.backgroundColor
                          : "#569A59",
                      }}
                    >
                      <div className="code-dec">Ver Código de Desconto</div>
                    </a>
                  ) : (
                    <div
                      className="discount-code-sec flex items-center justify-center cursor-pointer"
                      onClick={(e) => {
                        setSelectedPromotion(item);
                        setOpen(true);
                      }}
                      style={{
                        backgroundColor: colors.backgroundColor
                          ? colors.backgroundColor
                          : "#569A59",
                      }}
                    >
                      <div className="code-dec">Ver Código de Desconto</div>
                    </div>
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
