import React from "react";

function ListIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9643 2.74414H6.98524C4.64295 2.74414 2.74414 4.64295 2.74414 6.98524V16.9643C2.74414 19.3066 4.64295 21.2054 6.98524 21.2054H16.9643C19.3066 21.2054 21.2054 19.3066 21.2054 16.9643V6.98524C21.2054 4.64295 19.3066 2.74414 16.9643 2.74414Z"
        stroke="#1B1B1B"
        stroke-width="1.49686"
      />
      <path
        d="M6.98535 15.7671L8.108 16.9646L9.97907 14.9688"
        stroke="#1B1B1B"
        stroke-width="1.49686"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.98535 7.78368L8.108 8.98116L9.97907 6.98535"
        stroke="#1B1B1B"
        stroke-width="1.49686"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9727 7.9834H16.9643"
        stroke="#1B1B1B"
        stroke-width="1.49686"
        stroke-linecap="round"
      />
      <path
        d="M12.9727 15.9663H16.9643"
        stroke="#1B1B1B"
        stroke-width="1.49686"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default ListIcon;
