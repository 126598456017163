import { useNavigate } from "react-router-dom";
import context from "../../context/context";
import { useContext } from "react";

export function RescueHistory({ extracts }) {
  const navigate = useNavigate();
  const { colors } = useContext(context);
  return (
    <div className="container">
      {extracts.length === 0 ? (
        <div className="divNotExtracts">
          <div>
            <h2>Você ainda não fez compras.</h2>
            <p>
              Para ganhar seu primeiro Cashback, compre no site das Lojas
              Parcerias.
            </p>
          </div>
          <button
            onClick={() => {
              navigate("/");
            }}
            style={
              colors.backgroundColor
                ? { background: colors.backgroundColor}
                : null
            }
          >
            Acessar lojas
          </button>
        </div>
      ) : (
        extracts.map((el) => (
          <div key={el.id_compra} className="divExtracts">
            <div style={{ height: "100%" }}>
              <p
                style={{
                  margin: "5px 0px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                }}
              >{`ID: ${el.id}`}</p>
              <h3 style={{ fontWeight: 600, width: 300 }}>
                {`Valor Solicitado: R$ ${Number(el?.value)
                  .toFixed(2)
                  .replace(".", ",")}`}
              </h3>

              <p>
                Status:{" "}
                {!el.status ? (
                  <span style={{ color: "#FF651D", fontSize: 12 }}>
                    Pendente
                  </span>
                ) : (
                  <span style={{ color: "#379C44", fontSize: 12 }}>
                    Resgatado
                  </span>
                )}
              </p>
              <p>{`Data do pedido: ${new Date(
                el.requestDate
              ).toLocaleDateString()}`}</p>
              {el.status && (
                <p>{`Data do pagamento: ${new Date(el.paymentDate).toLocaleDateString()}`}</p>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <img
                width={110}
                src={
                  el.estabelecimento
                    ? `https://clubecerto.com.br/brand/${el.estabelecimento}.png`
                    : "./images/company-icon.svg"
                }
              />
              <h3>{el?.establishment?.programmeInfo?.name}</h3> */}
            </div>
          </div>
        ))
      )}
    </div>
  );
}
