export default function formatName(fullName) {
  console.log(fullName)
    const nameParts = fullName.split(' ');
    if (nameParts.length < 2) {
      return fullName;
    }
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1] || nameParts[nameParts.length - 2];
    
    return `${firstName} ${lastName}`;
  }