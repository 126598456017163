import { useContext } from "react";
import context from "../../context/context";
import style from "./style.scss";
import jwtDecode from "jwt-decode";

export default function RescueModal({ setOpen, value, handleSubmit }) {
  const { userToken, colors } = useContext(context);
  return (
    <div className="containerModal">
      <img
        src={
          !jwtDecode(userToken).selectedCompany
            ? "https://clubecerto.com.br/cashback/images/logo2.svg"
            : jwtDecode(userToken).selectedCompany.companiesImage.image
        }
        className="logo2"
      />

      <h2>Resgatar Saldo Disponível?</h2>
      <h4>Valor</h4>
      <h1>{`R$ ${value.toFixed(2).replace(".", ",")}`}</h1>
      <span
        style={{ background: colors.backgroundColor }}
      />
      <div style={{ background: colors.backgroundColor }}>
        <h5>
          <h3>
            O saldo mínimo do cashback reclamado para resgate é de: R$ 20,00,
            após resgatado o saldo será depositado em sua cadastrada em até 4
            dias úteis.
          </h3>
        </h5>
      </div>

      <button
        onClick={() => {
          handleSubmit();
          setOpen(false);
        }}
        style={{ background:colors.backgroundColor }}
      >
        Resgatar
      </button>
    </div>
  );
}
