import React from "react";

function TrollyIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        stroke={color || "#379C44"}
        strokeWidth="2.36"
        d="M14.16 28.32a2.36 2.36 0 110 4.72 2.36 2.36 0 010-4.72zM25.174 28.32a2.36 2.36 0 110 4.72 2.36 2.36 0 010-4.72zM9.047 18.88c0-2.258.002-3.833.162-5.022.155-1.154.44-1.766.875-2.201.435-.436 1.046-.72 2.201-.875 1.188-.16 2.764-.162 5.022-.162h4.59c2.622 0 4.462.003 5.83.197 1.335.19 2.002.535 2.442 1.055.441.52.672 1.235.64 2.582-.034 1.381-.334 3.197-.765 5.784-.319 1.913-.542 3.24-.838 4.235-.287.963-.604 1.473-1.023 1.828-.419.355-.974.584-1.97.708-1.03.129-2.376.131-4.316.131h-4.59c-2.258 0-3.834-.003-5.022-.162-1.155-.155-1.766-.44-2.201-.875-.436-.435-.72-1.047-.875-2.201-.16-1.189-.162-2.764-.162-5.022z"
      ></path>
      <path
        stroke={color || "#379C44"}
        strokeLinecap="round"
        strokeWidth="2.36"
        d="M9.046 18.093V7.867A3.147 3.147 0 005.9 4.72H3.933"
      ></path>
    </svg>
  );
}

export default TrollyIcon;
