import React from "react";

function ArrowUpIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      stroke={color||"#379C44"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      viewBox="0 0 24 24"
    >
      <path d="M18 15l-6-6-6 6"></path>
    </svg>
  );
}

export default ArrowUpIcon;
