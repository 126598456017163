import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../components/Loader";
import context from "../../context/context";

const LoginDirect = () => {
  const navigate = useNavigate();
  const { setUserStatus, userToken, setUserToken, setCharCode } =
    useContext(context);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  let [searchParams] = useSearchParams();
  const location = useLocation();

  const getQueryParam = (param) => {
    return searchParams.get(param);
  };

  const loginFunction = async () => {
    setError(null);
    console.log("Current route:", location.pathname);

    const data = getQueryParam("t");
    if (data) {
      setUserToken(data);
      setError("data", data);
    } else {
      const dynamicSegment = location.pathname.split("/").pop(); // Pega o último segmento da URL atual
      const newRoute = `/auth/login/${dynamicSegment}`;
      navigate(newRoute);
    }

    await new Promise((resolve) => setTimeout(resolve, 3000));
    setLoading(false);
  };

  useEffect(() => {
    console.log("entrou");
    loginFunction();
    if (userToken) {
      localStorage.setItem("userToken", userToken);
      localStorage.setItem("userStatus", "true");
      setUserStatus("true");
      navigate("/");
    }
    if (loading) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    }
    if (!loading) {
      document.body.style.overflow = "auto";
    }
  }, [loading]);

  return (
    <div
      className="login-page h-screen flex justify-center items-center"
      style={{ overflow: loading ? "hidden" : "auto" }}
    >
      {loading && <Loader />}
    </div>
  );
};

export default LoginDirect;
