import React from "react";

const Loader = ({ heavy }) => {
  return (
    <div
      className="loader-cmp flex items-center absolute h-full w-full"
      style={{
        background: !heavy
          ? "background: rgba(255, 255, 255, 0.65);"
          : "rgba(255, 255, 255, 0.95)",
      }}
    >
      <div className="lds-dual-ring"></div>
    </div>
  );
};

export default Loader;
