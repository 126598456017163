import React from "react";

export default function SettingsIcon({color}) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.56317 12.2083L5.94771 9.81018C6.76584 8.39314 8.60054 7.89002 10.0312 8.71602C12.4974 10.1399 14.5126 8.97795 14.5078 6.12288C14.5073 4.48753 15.8501 3.13721 17.499 3.14456L20.6351 3.15657C22.0812 3.13764 23.2508 4.32156 23.2697 5.7677L23.2701 6.11311C23.2613 8.96032 25.2779 10.1246 27.7529 8.70125L28.0521 8.52891C29.3141 7.82223 30.9241 8.24311 31.6308 9.50497L33.2092 12.2148C34.0401 13.6392 33.5421 15.4773 32.1255 16.2945C29.6505 17.7179 29.6519 20.0441 32.1181 21.4679C33.5352 22.2861 34.0384 24.1207 33.2124 25.5514L31.8278 27.9494C31.0097 29.3666 29.1749 29.8697 27.7442 29.0437C25.278 27.6198 23.2627 28.7817 23.2676 31.6367C23.2604 33.2857 21.9254 34.6224 20.2764 34.6152L17.1404 34.6031C15.6943 34.6221 14.5247 33.4382 14.5058 31.992L14.5054 31.6466C14.5142 28.7994 12.4976 27.6351 10.0226 29.0585L9.72326 29.2308C8.46139 29.9375 6.85132 29.5166 6.14464 28.2547L4.56624 25.5449C3.73538 24.1204 4.2334 22.2825 5.64992 21.4651C8.12491 20.0419 8.12355 17.7157 5.65735 16.2918C4.22667 15.4658 3.74504 13.6253 4.56317 12.2083Z"
        stroke={color ? color : "#379C44"}
        stroke-width="2.36"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.9933 18.8799C23.9933 16.0559 21.704 13.7666 18.8799 13.7666C16.0559 13.7666 13.7666 16.0559 13.7666 18.8799C13.7666 21.704 16.0559 23.9933 18.8799 23.9933C21.704 23.9933 23.9933 21.704 23.9933 18.8799Z"
        stroke={color ? color : "#379C44"}
        stroke-width="2.36"
      />
    </svg>
  );
}
