import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Select from "react-select";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { FreeMode, Pagination, Navigation } from "swiper";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import DiscountCodeView from "../../components/DiscountCodeView";
import api from "../../connections/API";
import starBucksBanner from "../../assets/images/starbucks.svg";
import {
  CartIcon,
  CarIcon,
  BebidasIcon,
  AlimentosIcon,
  BelezaIcon,
  CasaIcon,
  CelularIcon,
  SuplimentosIcon,
  EletrodomesticosIcon,
  EletronicosIcon,
  FerramentasIcon,
  EducacaoIcon,
  AcessoriosIcon,
  FutebolIcon,
  ModaIcon,
  GamesIcon,
  InfantilIcon,
  InformáticaIcon,
  SaúdeIcon,
  JoiasIcon,
  LivrariaIcon,
  OutrosIcon,
  PetIcon,
  PresentesIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from "../../assets/Icons";
import { useContext } from "react";
import context from "../../context/context";
import SearchBox from "../../components/SearchBox";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
const categoriesList = [
  { lbl: "Automotivo", icon: <CarIcon />, slug: "" },
  { lbl: "Bebidas", icon: <BebidasIcon />, slug: "" },
  { lbl: "Alimentos", icon: <AlimentosIcon />, slug: "" },
  { lbl: "Beleza", icon: <BelezaIcon />, slug: "" },
  { lbl: "Casa", icon: <CasaIcon />, slug: "" },
  { lbl: "Celular", icon: <CelularIcon />, slug: "" },
  { lbl: "Suplementos", icon: <SuplimentosIcon />, slug: "" },
  { lbl: "Educação", icon: <EducacaoIcon />, slug: "" },
  { lbl: "Eletrodomésticos", icon: <EletrodomesticosIcon />, slug: "" },
  { lbl: "Eletrônicos", icon: <EletronicosIcon />, slug: "" },
  { lbl: "Ferramentas", icon: <FerramentasIcon />, slug: "" },
  { lbl: "Acessórios", icon: <AcessoriosIcon />, slug: "" },
  { lbl: "Futebol", icon: <FutebolIcon />, slug: "" },
  { lbl: "Moda", icon: <ModaIcon />, slug: "" },
  { lbl: "Games", icon: <GamesIcon />, slug: "" },
  { lbl: "Infantil", icon: <InfantilIcon />, slug: "" },
  { lbl: "Informática", icon: <InformáticaIcon />, slug: "" },
  { lbl: "Joias", icon: <JoiasIcon />, slug: "" },
  { lbl: "Livraria", icon: <LivrariaIcon />, slug: "" },
  { lbl: "Outros", icon: <OutrosIcon />, slug: "" },
  { lbl: "Pet", icon: <PetIcon />, slug: "" },
  { lbl: "Presentes", icon: <PresentesIcon />, slug: "" },
  { lbl: "Saúde", icon: <SaúdeIcon />, slug: "" },
];
const faqList1 = [
  {
    question: "De onde vem o Cashback que eu recebo?",
    answer:
      "   A proposta do cashback é simples: as lojas pagam para anunciar no site e nós dividimos esse valor com você! As políticas de compra, métodos de pagamento, porcentagem de desconto e cashback são definidos pela própria loja.",
  },
  {
    question: "Em quanto tempo recebo meu Cashback?",
    answer:
      "   Você receberá o seu cashback dentro do prazo de 25 a 150 dias. O valor é creditado automaticamente na conta de sua preferência, pode ser de qualquer banco, mas não esqueça: é a conta que você cadastrou.",
  },
  {
    question: "Meu Cashback não apareceu no extrato, o que fazer?",
    answer:
      "  Ao efetuar a compra, geralmente leva-se poucos instantes para que apareça no extrato como pendente. Mas como isso varia de loja / integração esse prazo estende-se por até por 48h.",
  },
];
const faqList2 = [
  {
    question: "Também ganho Cashback se usar Cupom de Desconto?",
    answer:
      "   Alguns sites podem reduzir o valor do cashback caso seja utilizado cupom de desconto.",
  },
  {
    question: "Preciso solicitar resgate para receber o Cashback?",
    answer:
      "   Assim que a compra é confirmada pelo nosso sistema no dia seguinte realizamos o envio do cashback de acordo com as informações bancárias registradas no cadastro da pessoa. Sendo assim, mantenha sempre os dados atualizados e se possível tenha seu CPF como uma chave PIX.",
  },
  // {
  //   question: "Da pra ganhar mais Cashback?",
  //   answer:
  //     "   Há mais vantagens quando o cliente já é do Clube Certo. Para isto solicite a sua empresa ou associação para entrar em contato com o Clube Certo e aproveite sempre nossos cashbacks e descontos especiais",
  // },
];
const Main = () => {
  const {
    companiesListLoading,
    companiesListData,
    viewPromotionsListData,
    userToken,
    setCompaniesListData,
    size,
    colors,
  } = useContext(context);

  const [open, setOpen] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [name, setName] = useState("");
  const [page, setPage] = useState(0);
  const [moreLoading, setMoreLoading] = useState(false);
  async function handleSearch() {
    const data = await api.getCompaniesList({ token: userToken, name });
    setCompaniesListData(data);
  }

  async function morePage() {
    setMoreLoading(true);
    const data = await api.getCompaniesList({ token: userToken, name, page });
    setCompaniesListData([...companiesListData, ...data]);
    setMoreLoading(false);
  }
  const FaqItem = ({ item, index }) => {
    const [openFAQ, setOpenFAQ] = useState(false);
    return (
      <div className="faq-item flex flex-col">
        <div
          className="faq-row flex items-start"
          onClick={(e) => setOpenFAQ(!openFAQ)}
        >
          <div className="arrow-icon">
            {openFAQ ? (
              <ArrowUpIcon color={colors.backgroundColor} />
            ) : (
              <ArrowRightIcon color={colors.backgroundColor} />
            )}
          </div>
          <div className="question">{item.question}</div>
        </div>
        <div
          className={`answer ${openFAQ ? "show" : ""}`}
          style={
            colors.backgroundColor
              ? {
                  color: colors.backgroundColor,
                }
              : null
          }
        >
          {item.answer}
        </div>
      </div>
    );
  };

  const ChangeDataFormat = (date) => {
    let newDate = moment(date, "YYYY-MM-DD");
    let finalDate = newDate.format("DD-MM-YYYY");
    return finalDate;
  };
  useEffect(() => {
    handleSearch();
  }, [name]);

  useEffect(() => {
    if (page === 0) {
      return;
    }
    morePage();
  }, [page]);
  return (
    <>
      <Header />
      <div className="lading-page bg-themeColor2 h-screen flex flex-col">
        <div className="wrap wrapWidth flex flex-col">
          <div className="top-swiper">
            <Swiper
              slidesPerView={3.2}
              spaceBetween={30}
              freeMode={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination]}
              breakpoints={{
                // when window width is >= 1024px
                1024: {
                  width: 1024,
                  slidesPerView: 2.3,
                },
                // when window width is >= 768px
                768: {
                  width: 768,
                  slidesPerView: 1.5,
                },
                // when window width is >= 480px
                480: {
                  width: 480,
                  slidesPerView: 1.5,
                },
                300: {
                  width: 300,
                  slidesPerView: 1.1,
                },
              }}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="slider-card flex">
                  <Link className="card-bg" to={`/company/detail/17652`}>
                    <div className="card-logo flex items-center justify-center">
                      <img
                        src="https://clubecerto.com.br/cashback/images/Logo_Nike.png"
                        className="logo-img"
                      />
                    </div>
                    <div className="card-meta flex flex-col">
                      <div className="until-lbl">até</div>
                      <div className="numb">5%</div>
                      <div className="cash-back">de Cashback</div>
                      <div className="btn-login button flex items-center justify-center">
                        <div className="btn-icon flex items-center justify-center">
                          <CartIcon />
                        </div>
                        <div className="btn-lbl">Loja Online</div>
                      </div>
                    </div>
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <Link className="slider-card flex" to={`/company/detail/17780`}>
                  <div className="card-bg">
                    <div className="card-logo flex items-center justify-center">
                      <img
                        src="https://clubecerto.com.br/cashback/images/logo_Mac.png"
                        className="logo-img"
                      />
                    </div>
                    <div className="card-meta flex flex-col">
                      <div className="until-lbl">até</div>
                      <div className="numb">7%</div>
                      <div className="cash-back">de Cashback</div>
                      <div className="btn-login button flex items-center justify-center">
                        <div className="btn-icon flex items-center justify-center">
                          <CartIcon />
                        </div>
                        <div className="btn-lbl">Loja Online</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className="slider-card flex" to={`/company/detail/17874`}>
                  <div className="card-bg">
                    <div className="card-logo flex items-center justify-center">
                      <img
                        src="https://clubecerto.com.br/cashback/images/Logo_Extra.png"
                        className="logo-img"
                      />
                    </div>
                    <div className="card-meta flex flex-col">
                      <div className="until-lbl">até</div>
                      <div className="numb">3%</div>
                      <div className="cash-back">de Cashback</div>
                      <div className="btn-login button flex items-center justify-center">
                        <div className="btn-icon flex items-center justify-center">
                          <CartIcon />
                        </div>
                        <div className="btn-lbl">Loja Online</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className="slider-card flex" to={`/company/detail/17662`}>
                  <div className="card-bg">
                    <div className="card-logo flex items-center justify-center">
                      <img
                        src="https://clubecerto.com.br/cashback/images/vivara.svg"
                        className="logo-img"
                      />
                    </div>
                    <div className="card-meta flex flex-col">
                      <div className="until-lbl">até</div>
                      <div className="numb">5.5%</div>
                      <div className="cash-back">de Cashback</div>
                      <div className="btn-login button flex items-center justify-center">
                        <div className="btn-icon flex items-center justify-center">
                          <CartIcon />
                        </div>
                        <div className="btn-lbl">Loja Online</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className="slider-card flex" to={`/company/detail/17658`}>
                  <div className="card-bg">
                    <div className="card-logo flex items-center justify-center">
                      <img
                        src="https://clubecerto.com.br/cashback/images/Logo_Natura.png"
                        className="logo-img"
                      />
                    </div>
                    <div className="card-meta flex flex-col">
                      <div className="until-lbl">até</div>
                      <div className="numb">9%</div>
                      <div className="cash-back">de Cashback</div>
                      <div className="btn-login button flex items-center justify-center">
                        <div className="btn-icon flex items-center justify-center">
                          <CartIcon />
                        </div>
                        <div className="btn-lbl">Loja Online</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
              <SwiperSlide>
                <Link className="slider-card flex" to={`/company/detail/17648`}>
                  <div className="card-bg">
                    <div className="card-logo flex items-center justify-center">
                      <img
                        src="https://clubecerto.com.br/cashback/images/Logo_C&A.png"
                        className="logo-img"
                      />
                    </div>
                    <div className="card-meta flex flex-col">
                      <div className="until-lbl">até</div>
                      <div className="numb">8.5%</div>
                      <div className="cash-back">de Cashback</div>
                      <div className="btn-login button flex items-center justify-center">
                        <div className="btn-icon flex items-center justify-center">
                          <CartIcon />
                        </div>
                        <div className="btn-lbl">Loja Online</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="short-by"></div>
        </div>
        <div className="separator"></div>
        <div className="wrap2 wrapWidth flex flex-col">
          <div
            className={`flex justify-evenly ${size < 1100 ? "flex-col" : ""}`}
          >
            <div
              className="sec-meta flex flex-col"
              style={{ width: 500, maxWidth: "90%" }}
            >
              <div
                className="discount-tag"
                style={
                  size < 1100
                    ? {
                        textAlign: "center",
                        color: colors.backgroundColor,
                      }
                    : { color: colors.backgroundColor }
                }
              >
                Descontos e cashback
              </div>
              <div
                className="discount-desc"
                style={
                  size < 1100 ? { textAlign: "center", marginBottom: 20 } : null
                }
              >
                Descubra quais os estabelecimentos com as melhores ofertas e
                cashback.
              </div>
            </div>
            <SearchBox
              value={name}
              setValue={setName}
              customStyles={{ alignSelf: "flex-end", maxWidth: 500 }}
            />
          </div>
          {!companiesListLoading && (
            <div className="companies-list">
              {companiesListData?.map((item, index) => (
                <Link
                  to={`/company/detail/${item.establishmentId}`}
                  key={index}
                  className="company-card flex flex-col"
                >
                  <div className="company-info flex flex-col items-center justify-center">
                    <div className="company-logo flex flex-col items-center justify-center">
                      <img
                        src={
                          item.id
                            ? `https://clubecerto.com.br/brand/${item.establishmentId}.png`
                            : "./images/company-icon.svg"
                        }
                        alt={index}
                        className="c-logo"
                      />
                    </div>
                    <div className="company-name">
                      {item.name ? item.name : " - "}
                    </div>
                  </div>
                  <div className="company-discount">
                    <div
                      className="disc-tag"
                      style={
                        colors.backgroundColor
                          ? {
                              color: colors.backgroundColor,
                            }
                          : null
                      }
                    >
                      Ver Cashback
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
        {companiesListData.length >= 10 && (
          <button
            style={{
              backgroundColor: colors.backgroundColor
                ? colors.backgroundColor
                : "#569A59",
              width: 200,
              alignSelf: "center",
              color: "white",
              padding: "8px 0px",
              borderRadius: "25px",
            }}
            onClick={() => {
              if (moreLoading || companiesListData.length < 10) {
                return;
              }
              setPage((state) => state + 1);
            }}
          >
            {moreLoading ? "Carregando..." : "Ver Mais"}
          </button>
        )}
        <div className="separator"></div>
        <div className="wrap4 wrapWidth flex flex-col">
          <div className="sec-meta flex flex-col">
            <div
              className="discount-tag text-center"
              style={
                colors.backgroundColor
                  ? {
                      color: colors.backgroundColor,
                    }
                  : null
              }
            >
              Ficou com alguma dúvida?
            </div>
            <div className="discount-desc text-center">
              Veja se essas respostas ajudam. Se precisar, fale com a gente!
            </div>
          </div>
          <div className="faq-sec flex items-center">
            <div className="faq-left flex flex-col">
              {faqList1.map((item, index) => (
                <FaqItem item={item} index={index} />
              ))}
            </div>
            <div className="faq-right flex flex-col">
              {faqList2.map((item, index) => (
                <FaqItem item={item} index={index} />
              ))}
            </div>
          </div>
        </div>
        {companiesListLoading ? <Loader /> : <></>}
        <Modal open={open} onClose={() => setOpen(false)}>
          <DiscountCodeView
            setOpen={setOpen}
            selectedPromotion={selectedPromotion}
          />
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default Main;
