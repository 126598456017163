import { Navigate, useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import context from "../context/context";

const Private = ({ children }) => {
  const { userToken, charCode, setCharCode } = useContext(context);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Para acessar um parâmetro específico, você pode usar get():
  const paramValue = queryParams.get("charcode");

  useEffect(() => {
    // Verifica o localStorage primeiro
    const storedCharCode = localStorage.getItem("charCode");
    if (storedCharCode) {
      setCharCode(storedCharCode);
    } else if (paramValue) {
      // Se não estiver no localStorage, usa o parâmetro da URL
      setCharCode(paramValue);
    }
  }, [paramValue, setCharCode]);

  setCharCode(paramValue);
  return userToken ? (
    children
  ) : (
    <Navigate
      to={`/auth/login/${
        localStorage.getItem("charCode") || paramValue || charCode || ""
      }`}
    />
  );
  // return children
};

export default Private;
