import React from "react";

function WalletIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        stroke={color ? color : "#379C44"}
        strokeWidth="2.36"
        d="M29.893 15.34H28.32a3.54 3.54 0 100 7.08h1.573a3.54 3.54 0 100-7.08z"
      ></path>
      <path
        fill={color ? color : "#379C44"}
        d="M15.733 8.653h4.72c3.034 0 5.071.005 6.591.21 1.453.195 2.074.532 2.484.942.41.41.747 1.032.943 2.484.124.922.174 2.035.195 3.444h2.36c-.056-3.9-.345-6.113-1.83-7.596-1.842-1.844-4.81-1.844-10.743-1.844h-4.72c-5.933 0-8.9 0-10.743 1.844-1.843 1.843-1.843 4.81-1.843 10.743 0 5.933 0 8.9 1.843 10.743 1.843 1.844 4.81 1.844 10.743 1.844h4.72c5.934 0 8.9 0 10.744-1.844 1.484-1.483 1.773-3.695 1.83-7.596h-2.361c-.02 1.41-.071 2.522-.195 3.444-.196 1.452-.533 2.073-.943 2.484-.41.41-1.031.747-2.484.942-1.52.205-3.557.21-6.59.21h-4.72c-3.034 0-5.072-.005-6.592-.21-1.452-.195-2.073-.532-2.483-.942-.41-.41-.748-1.032-.943-2.484-.204-1.52-.21-3.558-.21-6.591 0-3.033.006-5.071.21-6.591.195-1.452.533-2.073.943-2.484.41-.41 1.031-.747 2.483-.942 1.52-.205 3.558-.21 6.591-.21z"
      ></path>
      <path
        stroke={color ? color : "#379C44"}
        strokeLinecap="round"
        strokeWidth="2.36"
        d="M10.62 14.553h8.653"
      ></path>
    </svg>
  );
}

export default WalletIcon;
