import React from "react";

function SearchIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        stroke={color || "#569A59"}
        strokeWidth="2.36"
        d="M4.327 17.307c0-7.169 5.81-12.98 12.98-12.98 7.168 0 12.98 5.811 12.98 12.98 0 7.168-5.812 12.98-12.98 12.98-7.17 0-12.98-5.812-12.98-12.98zM29.5 31.467a1.967 1.967 0 113.933 0 1.967 1.967 0 01-3.933 0z"
      ></path>
    </svg>
  );
}

export default SearchIcon;
