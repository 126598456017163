import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import SearchBox from "../SearchBox";
import { ArrowDownIcon } from "../../assets/Icons";
import context from "../../context/context";
import defaultPerfil from "../../assets/Icons/default-perfil.png";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";

const Header = ({ openSidebar, setOpenSidebar }) => {
  const { logout, userStatus, size, user, userToken } = useContext(context);
  const { pathname } = useLocation();

  const userLogout = () => {
    logout();
  };
  useEffect(() => {
    console.log(user);
  }, []);
  return (
    <div className="header-camp flex  " style={{ padding: "0px 20px" }}>
      <div className="wrapWidth wrap flex items-center ">
        <div className="left flex items-center ">
          <div className="logo-img flex items-center justify-center">
            <Link to="/ ">
              <img
                src={
                  size >= 900 && !jwtDecode(userToken).selectedCompany
                    ? "https://clubecerto.com.br/cashback/images/logo.svg"
                    : jwtDecode(userToken).selectedCompany
                    ? jwtDecode(userToken).selectedCompany.companiesImage.image
                    : "https://clubecerto.com.br/cashback/images/logo2.svg"
                }
                alt={"logo"}
                style={{ minWidth: 80 }}
              />
            </Link>
          </div>
        </div>
        <div className="center flex items-center">{/* <SearchBox /> */}</div>
        <div className="right flex items-center justify-end">
          {/* <div className="actions flex items-center">
            <Link to="/auth/register" className="btn-register button">
              <p className="mt-[3px]">Cadastrar</p>
            </Link>
            {
            userStatus === 'true'
            ? <button to="/auth/login" className="btn-login button" onClick={userLogout}>
                <p className="mt-[3px]">Logout</p>
              </button>
            : <Link to="/auth/login" className="btn-login button">
                <p className="mt-[3px]">Login</p>
              </Link>
            }
          </div> */}
          {/* <div className="menus-block flex items-center">
            <div className="menu-box flex">
              <div className="m-left flex flex-col">
                <div className="name">País</div>
                <div className="designation">Brasil</div>
              </div>
              <div className="m-right flex items-center justify-center">
                <ArrowDownIcon />
              </div>
            </div>
            <div className="menu-box flex">
              <div className="m-left flex flex-col">
                <div className="name">Idioma</div>
                <div className="designation">PT/BR</div>
              </div>
              <div className="m-right flex items-center justify-center">
                <ArrowDownIcon />
              </div>
            </div>
          </div> */}
          {pathname.includes("dashboard/home") ? (
            <></>
          ) : (
            <Link to={"/dashboard/home"}>
              {user?.userImage ? (
                <div
                  style={{
                    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 25%))",
                    width: 65,
                    height: 65,
                    borderRadius: "50%",
                    backgroundImage: `url(${user?.userImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              ) : (
                <img
                  className="perfilImg"
                  width={70}
                  src={defaultPerfil}
                  alt="perfil"
                />
              )}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
