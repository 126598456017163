import React from "react";

function Exit({ color }) {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.9205 10.1736L10.0405 29.0537"
        stroke={color || "#379C44"}
        stroke-width="2.36"
        stroke-linecap="round"
      />
      <path
        d="M28.9205 29.0537L10.0405 10.1736"
        stroke={color || "#379C44"}
        stroke-width="2.36"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default Exit;
