import React from "react";

function CopayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="47"
      height="47"
      fill="none"
      viewBox="0 0 47 47"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.413 11.082c-4.19.023-6.46.209-7.94 1.69-1.693 1.692-1.693 4.417-1.693 9.866v8.189c0 5.449 0 8.174 1.693 9.867 1.693 1.692 4.418 1.693 9.867 1.693h1.927c5.45 0 8.174 0 9.867-1.693 1.168-1.168 1.53-2.827 1.642-5.532h-2.892c-.028.615-.07 1.148-.133 1.622-.169 1.253-.44 1.646-.66 1.866-.22.22-.614.492-1.867.66-1.337.18-3.15.187-5.957.187H17.34c-2.806 0-4.62-.007-5.957-.186-1.254-.169-1.647-.441-1.867-.661-.22-.22-.492-.613-.66-1.866-.18-1.337-.186-3.151-.186-5.957v-8.189c0-2.806.006-4.62.186-5.957.168-1.254.44-1.646.66-1.866.22-.22.613-.492 1.867-.661 1.002-.135 2.274-.172 4.03-.182v-2.89z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_20_1670"
        style={{ maskType: "alpha" }}
        width="26"
        height="33"
        x="15"
        y="3"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M33.364 6.11a7.706 7.706 0 00-5.45-2.256h-6.72a5.78 5.78 0 00-5.78 5.78v19.748a5.78 5.78 0 005.78 5.78H34.68a5.78 5.78 0 005.78-5.78V16.399a7.706 7.706 0 00-2.257-5.45l-4.839-4.838z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_20_1670)">
        <path
          fill="#fff"
          d="M33.364 6.11l-2.043 2.044 2.043-2.043zm-12.17.633h6.72V.963h-6.72v5.78zm-2.89 2.89a2.89 2.89 0 012.89-2.89V.963a8.67 8.67 0 00-8.67 8.67h5.78zm0 19.749V9.633h-5.78v19.749h5.78zm2.89 2.89a2.89 2.89 0 01-2.89-2.89h-5.78a8.67 8.67 0 008.67 8.67v-5.78zm13.486 0H21.193v5.78H34.68v-5.78zm2.89-2.89a2.89 2.89 0 01-2.89 2.89v5.78a8.67 8.67 0 008.67-8.67h-5.78zm0-12.983v12.983h5.78V16.399h-5.78zm2.676-7.493l-4.838-4.839-4.087 4.087 4.838 4.839 4.087-4.087zm3.104 7.493c0-2.81-1.116-5.506-3.104-7.493l-4.087 4.087a4.817 4.817 0 011.411 3.406h5.78zM27.915 6.743c1.277 0 2.502.508 3.406 1.411l4.087-4.087A10.597 10.597 0 0027.915.963v5.78z"
        ></path>
      </g>
    </svg>
  );
}

export default CopayIcon;
