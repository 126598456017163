import React from "react";

function ExclamationIcon({ onClick }) {
  return (
    <svg
      onClick={onClick}
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0063 18.88C35.0063 9.97346 27.7861 2.7533 18.8796 2.7533C9.97309 2.7533 2.75293 9.97346 2.75293 18.88C2.75293 27.7865 9.97309 35.0067 18.8796 35.0067C27.7861 35.0067 35.0063 27.7865 35.0063 18.88Z"
        stroke="#569A59"
        stroke-width="2.36"
      />
      <path
        d="M16.4558 8V24.7986H20.7205V8H16.4558ZM16 28.4123C16 31.8306 21.2089 31.8306 21.2089 28.4123C21.2089 24.9614 16 24.9614 16 28.4123Z"
        fill="white"
      />
    </svg>
  );
}

export default ExclamationIcon;
