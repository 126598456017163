import React from "react";

function InboxIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        stroke={color || "#379C44"}
        strokeWidth="2.36"
        d="M26.747 4.327H11.013a6.687 6.687 0 00-6.686 6.686v15.734a6.687 6.687 0 006.686 6.686h15.734a6.687 6.687 0 006.686-6.686V11.013a6.687 6.687 0 00-6.686-6.686z"
      ></path>
      <mask
        id="mask0_18_1431"
        style={{ maskType: "alpha" }}
        width="32"
        height="17"
        x="3"
        y="18"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M15.143 21.24a3.116 3.116 0 00-3.022-2.36H9.44c-3.042 0-4.563 0-5.493.978-.93.977-.858 2.424-.714 5.316.172 3.45.686 5.603 2.218 7.135 2.304 2.304 6.012 2.304 13.429 2.304 7.417 0 11.125 0 13.43-2.304 1.531-1.532 2.044-3.684 2.216-7.135.145-2.892.217-4.338-.713-5.316-.93-.978-2.451-.978-5.493-.978h-2.68c-1.43 0-2.677.973-3.023 2.36a3.116 3.116 0 01-3.023 2.36h-1.428a3.115 3.115 0 01-3.023-2.36z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_18_1431)">
        <path
          fill={color || "#379C44"}
          d="M34.526 25.174l-2.357-.118 2.357.118zm-30.58-5.316L2.238 18.23l1.71 1.627zm5.494 1.382h2.68v-4.72H9.44v4.72zm-2.32 9.4c-.86-.858-1.361-2.2-1.53-5.584l-4.714.235c.175 3.518.7 6.482 2.906 8.687l3.337-3.337zm11.76 1.613c-3.775 0-6.37-.005-8.318-.267-1.88-.252-2.802-.705-3.443-1.345l-3.337 3.337c1.663 1.664 3.747 2.363 6.151 2.686 2.336.314 5.305.31 8.947.31v-4.72zm11.76-1.612c-.64.64-1.563 1.093-3.443 1.345-1.948.262-4.542.267-8.317.267v4.72c3.641 0 6.61.005 8.946-.309 2.404-.323 4.488-1.022 6.152-2.686l-3.338-3.337zm1.53-5.585c-.17 3.384-.671 4.726-1.53 5.585l3.338 3.337c2.205-2.205 2.73-5.17 2.905-8.687l-4.714-.235zm-6.531-3.816h2.68v-4.72h-2.68v4.72zm-6.045 0h-1.428v4.72h1.428v-4.72zm-1.428 0a.755.755 0 01-.733-.572l-4.58 1.144a5.476 5.476 0 005.313 4.148v-4.72zm2.16-.572a.756.756 0 01-.732.572v4.72a5.476 5.476 0 005.312-4.148l-4.58-1.144zm5.313-4.148a5.476 5.476 0 00-5.312 4.148l4.579 1.144a.755.755 0 01.733-.572v-4.72zM12.12 21.24c.347 0 .65.236.734.572l4.579-1.144a5.476 5.476 0 00-5.313-4.148v4.72zm24.763 4.051c.069-1.374.139-2.66.039-3.716-.11-1.148-.44-2.336-1.4-3.344l-3.42 3.253c.01.01.015.014.03.054.023.058.064.197.09.483.061.635.023 1.518-.053 3.035l4.714.235zM28.32 21.24c1.59 0 2.536.005 3.212.1.309.043.461.093.528.122a.37.37 0 01.031.015l.01.005h-.002l.004.003 3.42-3.254c-.978-1.027-2.187-1.405-3.34-1.566-1.077-.15-2.412-.145-3.863-.145v4.72zM9.44 16.52c-1.452 0-2.787-.005-3.864.145-1.153.16-2.362.54-3.339 1.566l3.42 3.253.003-.003s-.001.001 0 0l.008-.004a.354.354 0 01.031-.015c.067-.029.22-.08.528-.122.676-.095 1.622-.1 3.213-.1v-4.72zm-3.85 8.536c-.076-1.517-.114-2.4-.053-3.035.027-.286.067-.425.09-.483.015-.04.021-.044.03-.053l-3.42-3.254c-.959 1.008-1.29 2.196-1.4 3.344-.1 1.056-.03 2.342.039 3.716l4.714-.235z"
        ></path>
      </g>
    </svg>
  );
}

export default InboxIcon;
