import { useNavigate } from "react-router-dom";
import style from "./style.scss";
import { useContext } from "react";
import context from "../../context/context";

export function ExtractSection({ extracts }) {
  const navigate = useNavigate();
  const { colors } = useContext(context);
  return (
    <div className="container">
      {extracts.length === 0 ? (
        <div className="divNotExtracts">
          <div>
            <h2>Você ainda não fez compras.</h2>
            <p>
              Para ganhar seu primeiro Cashback, compre no site das Lojas
              Parcerias.
            </p>
          </div>
          <button
            onClick={() => {
              navigate("/");
            }}
            style={
              colors.backgroundColor
                ? { background: colors.backgroundColor }
                : null
            }
          >
            Acessar lojas
          </button>
        </div>
      ) : (
        extracts.map((el) => (
          <div key={el?.id} className="divExtracts">
            <div style={{ height: "100%" }}>
              <p
                style={{
                  margin: "5px 0px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                }}
              >{` ID: ${el?.purchaseId}`}</p>
              <h3 style={{ fontWeight: 600 }}>{` ${el?.establishmentName}`}</h3>
              <p>{`Valor do cashback: R$ ${Number(el.cashbackValue)
                .toFixed(2)
                .replace(".", ",")}`}</p>
              <p>
                Status:{" "}
                {el.status === "pending" ? (
                  <span style={{ color: "#FF651D", fontSize: 12 }}>
                    Pendente
                  </span>
                ) : el.status === "declined" ? (
                  <span style={{ color: "red", fontSize: 12 }}>Reprovado</span>
                ) : (
                  <span style={{ color: "#379C44", fontSize: 12 }}>
                    Aprovado
                  </span>
                )}
              </p>
              <p>{`Comprado: ${new Date(el.date).toLocaleDateString()}`}</p>
              <p>{`Pago em até ${
                el.paymentTime ? Number(el.paymentTime) + 5 : 60
              } dias`}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                width={110}
                src={
                  el?.establishmentId
                    ? `https://clubecerto.com.br/brand/${el.establishmentId}.png`
                    : "./images/company-icon.svg"
                }
              />
              <h3>{el?.establishment?.programmeInfo?.name}</h3>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
