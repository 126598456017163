import React, { useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import {
  HomeIcon,
  WalletIcon,
  InboxIcon,
  TrollyIcon,
} from "../../assets/Icons";
import context from "../../context/context";
import { useState } from "react";
import api from "../../connections/API";
import jwtDecode from "jwt-decode";
import { cpfSpecialCharactersRemover } from "../../Helpers/CpfUtils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import moment from "moment";
import Modal from "../../components/Modal";
import DiscountCodeView from "../../components/DiscountCodeView";
import { SwiperPromotions } from "../../components/SwiperPromotions";

const CompanyDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userStatus, companiesListLoading, userToken, colors } =
    useContext(context);

  const [companyLoading, setCompanyLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);

  const [open, setOpen] = useState(false);
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [viewPromotionsListData, setViewPromotionsListData] = useState([]);

  const loadCompany = async () => {
    setCompanyLoading(true);
    const [data, data2] = await Promise.all([
      api.companiesDetail({ id, token: userToken }),
      api.viewPromotions({ id }),
    ]);
    setCompanyData(data);
    setViewPromotionsListData(data2);
    setCompanyLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadCompany();
  }, []);

  return (
    <>
      <Header />
      <div className="company-detail-page flex">
        <div className="wrap wrapWidth flex items-start flex-col">
          <div className="page-route flex items-center">
            <div
              onClick={() => {
                navigate("/");
              }}
              className="flex items-center cursor-pointer"
            >
              <div className="icon flex items-center justify-center">
                <HomeIcon />
              </div>
            </div>
            <div className="current-page-name">
              / {companyData?.name ? companyData?.name : "-"}
            </div>
          </div>
          <div className="page-top-sec flex items-center justify-center">
            <div className="top-sec-box flex items-center justify-between">
              <div className="box-left-side flex items-center justify-center">
                <div className="item flex flex-col justify-center items-center">
                  <div className="company-logo flex items-center justify-center">
                    <img
                      src={
                        companyData
                          ? `https://clubecerto.com.br/brand/${id}.png`
                          : "/images/company-icon.svg"
                      }
                      className="img-logo"
                    />
                  </div>
                  <div
                    className="company-name"
                    style={{
                      color: colors.backgroundColor,
                    }}
                  >
                    {companyData ? companyData?.name : "-"}
                  </div>
                </div>
              </div>
              <div className="box-right-side flex flex-col">
                <div className="item flex flex-col justify-center items-center">
                  <div className="amount-lbl">Até </div>
                  <div className="amount">
                    {companyData ? Number(companyData?.max).toFixed(1) : "0.0"}%
                  </div>
                  <div className="amount-lbl">de volta</div>
                  <div className="action flex items-center justify-center">
                    {userStatus === "true" ? (
                      <a
                        href={companyData?.link}
                        // target="_blank"
                        className="btn button"
                        style={{
                          background: colors.backgroundColor,
                          borderColor: colors.backgroundColor,
                        }}
                      >
                        <p className="mt-1">Ativar Cashback</p>
                      </a>
                    ) : (
                      <Link
                        to="/auth/login"
                        className="btn button"
                        style={{
                          background: colors.backgroundColor,
                          borderColor: colors.backgroundColor,
                        }}
                      >
                        <p className="mt-1">Ativar Cashback</p>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-btm flex items-center justify-center">
            <div className="btm-box flex items-center justify-between">
              <div className="meta-side flex flex-col">
                <div
                  className="offer-tag"
                  style={{
                    color: colors.backgroundColor,
                  }}
                >
                  Regras
                </div>
                <div className="back-amount">
                  Regras para utilização do cashback
                </div>
                <div className="desc">{companyData?.rule}</div>
              </div>
              <div className="action flex items-center justify-center"></div>
            </div>
          </div>
          {!viewPromotionsListData.length ? (
            <></>
          ) : (
            <SwiperPromotions
              promotions={viewPromotionsListData}
              name={companyData.name}
              setSelectedPromotion={setSelectedPromotion}
              setOpen={setOpen}
            />
          )}
          <div className="page-center-sec">
            <div className="detail-card flex flex-col">
              <div className="card-t flex items-center justify-between">
                <div className="card-lbl">Pague como quiser</div>
                <div className="icon flex items-center justify-center">
                  <WalletIcon color={colors.backgroundColor} />
                </div>
              </div>
              <div className="card-dec">
                Você pode finalizar a compra e pagar como for melhor para você
              </div>
            </div>
            <div className="detail-card flex flex-col">
              <div className="card-t flex items-center justify-between">
                <div className="card-lbl">Não acesse outros sites</div>
                <div className="icon flex items-center justify-center">
                  <TrollyIcon color={colors.backgroundColor} />
                </div>
              </div>
              <div className="card-dec">
                Compre normalmente sem sair do fluxo de navegação até finalizar
                a compra.
              </div>
            </div>
            <div className="detail-card flex flex-col">
              <div className="card-t flex items-center justify-between">
                <div className="card-lbl">Use E-mail e CPF</div>
                <div className="icon flex items-center justify-center">
                  <InboxIcon color={colors.backgroundColor} />
                </div>
              </div>
              <div className="card-dec">
                Ao fazer login ou cadastro no site dos parceiros, utilize o
                mesmo e-mail e CPF cadastrados aqui.
              </div>
            </div>
          </div>
        </div>
        {companyLoading ? <Loader /> : <></>}
        <Modal open={open} onClose={() => setOpen(false)}>
          <DiscountCodeView
            setOpen={setOpen}
            selectedPromotion={selectedPromotion}
          />
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default CompanyDetail;
