import React from "react";

import { CopayIcon } from "../../assets/Icons";
import moment from "moment";
import { toast } from "react-toastify";
import { useContext } from "react";
import context from "../../context/context";

const DiscountCodeView = ({ setOpen, selectedPromotion }) => {
  const { colors } = useContext(context);
  function openLink(url) {
    window.open(url, "_blank");
    setOpen(false);
  }
  const copyTextToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Código Copiado!", {
        position: "bottom-center",
        autoClose: 2000,
      });
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  const ChangeDataFormat = (date) => {
    let newDate = moment(date, "YYYY-MM-DD");
    let finalDate = newDate.format("DD-MM-YYYY");
    return finalDate;
  };
  return (
    <div className="discount-code-view flex justify-center">
      <div
        className="discount-view-wrap flex flex-col"
        style={{ background: colors.backgroundColor }}
      >
        <div className="discount-top flex flex-col">
          <div className="company-logo flex items-center justify-center">
            <img
              src={
                selectedPromotion.estabelecimento
                  ? `https://clubecerto.com.br/brand/${selectedPromotion.estabelecimento}.png`
                  : "https://clubecerto.com.br/cashback/images/logo4.svg"
              }
              className="img-logo"
            />
          </div>
          <div className="square flex flex-col">
            <div className="square-tag">
              {selectedPromotion.estabelecimento} - {selectedPromotion.name}
            </div>
            <div className="separator"></div>
            <div className="valid-val text-center">
              Válido até dia {ChangeDataFormat(selectedPromotion.endDate)}
            </div>
          </div>
        </div>
        <div className="discount-btm flex flex-col">
          <div className="actions flex flex-col items-center justify-center">
            <div
              className="btn-code button"
              onClick={(e) => {
                copyTextToClipboard(selectedPromotion.code);
              }}
              style={{
                color: colors.backgroundColor,
                borderColor: colors.backgroundColor,
              }}
            >
              <p className="mt-1">{selectedPromotion.code}</p>
            </div>
            <button
              className="copy-btn flex items-center"
              style={{
                background: colors.backgroundColor,
              }}
            >
              <div className="btn-icon flex justify-center items-center">
                <CopayIcon />
              </div>
              <div
                className="btn-lbl mt-1"
                onClick={async () => {
                  await copyTextToClipboard(selectedPromotion.code);
                  openLink(selectedPromotion.urlTracking);
                }}
              >
                Copiar e Ativar Cashback
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountCodeView;
