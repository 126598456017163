import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./style.scss";
import defaultPerfil from "../../assets/Icons/default-perfil.png";
import { WalletIcon } from "../../assets/Icons";
import SettingsIcon from "../../assets/Icons/SettingIcon";
import { useState } from "react";
import { ExtractSection } from "../../components/ExtractSection";
import { Settings } from "../../components/Settings";
import { useEffect } from "react";

import context from "../../context/context";
import { useContext } from "react";
import jwtDecode from "jwt-decode";
import Loader from "../../components/Loader";
import Exit from "../../assets/Icons/Exit";
import formatName from "../../Helpers/FormatName";
import InterrogationIcon from "../../assets/Icons/InterrogationIcon";
import FaqModal from "../../components/FaqModal";
import Modal from "../../components/Modal";
import ExclamationIcon from "../../assets/Icons/ExclamationIcon";
import RescueModal from "../../components/RescueModal";
import Upload from "../../assets/Icons/Upload";
import { RescueHistory } from "../../components/RescueHistory";
import { toast } from "react-toastify";
import api from "../../connections/API";
export default function Extract() {
  const {
    userToken,
    logout,
    extracts,
    approved,
    pending,
    loadExtract,
    loading,
    loadCashbackHistory,
    cashbackHistory,
    postRescue,
    setLoading,
    user,
    setUser,
    colors,
  } = useContext(context);

  const [navType, setNavType] = useState("extract");
  const [open, setOpen] = useState(false);
  const [faqType, setFaqType] = useState("p");
  const [openRecue, setOpenRecue] = useState(false);

  async function refecthFunctions() {
    await Promise.all([loadCashbackHistory(), loadExtract()]);
  }
  async function rescueSubmit() {
    const rescue = await postRescue();
    if (!rescue) {
      setOpenRecue(false);
      return;
    }
    await refecthFunctions();
    setOpenRecue(false);
    toast.success(
      <>
        Saldo solicitado para resgate <br /> Aguarde até <b>2 dias úteis </b>
        para aprovação
      </>
    );
  }

  useEffect(() => {
    refecthFunctions();
  }, []);

  return (
    <>
      <Header />
      <div
        className="greenContainer"
        style={{
          background: colors.backgroundColor
            ? `linear-gradient(92.05deg, ${
                colors.backgroundColor
              } 1.8%, #01160d 94.62%)`
            : "linear-gradient(92.05deg, #569a59 1.8%, #01160d 94.62%)",
        }}
      >
        <div className="perfilContainer">
          {user?.userImage ? (
            <div
              style={{
                width: 110,
                height: 110,
                borderRadius: "50%",
                backgroundImage: `url(${user?.userImage})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            />
          ) : (
            <img alt="defaultProfile" src={defaultPerfil} width={110} />
          )}
          <h2>{user?.name ? formatName(user?.name) : ""}</h2>
        </div>
        <div className="valuesContainer">
          <div>
            <h1>{`R$${approved.toFixed(2).replace(".", ",")}`}</h1>
            <h3>Dísponivel</h3>
            <div className="RescueContainer">
              <button
                onClick={() => {
                  setOpenRecue(true);
                }}
                disabled={Number(approved) >= 20 ? false : true}
                style={{
                  background:
                    Number(approved) >= 20
                      ? colors.backgroundColor
                      : null,
                }}
              >
                Resgatar
              </button>
              <ExclamationIcon
                onClick={() => {
                  setFaqType("d");
                  setOpen(true);
                }}
              />
            </div>
          </div>
          <div>
            <h1>{`R$${pending.toFixed(2).replace(".", ",")}`}</h1>
            <h3>Pendente</h3>
            <ExclamationIcon
              onClick={() => {
                setFaqType("p");
                setOpen(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="mainContainer">
        <ul>
          <li
            onClick={() => {
              setNavType("extract");
            }}
            style={
              navType === "extract" && colors.backgroundColor
                ? {
                    background: colors.backgroundColor,
                    color: "#fff",
                  }
                : navType === "extract" && !colors.backgroundColor
                ? { background: "#569A59", color: "#fff" }
                : null
            }
          >
            <WalletIcon
              color={navType === "extract" ? "#fff" : colors.backgroundColor}
            />
            Extrato de Cashback
          </li>
          <li
            onClick={() => {
              setNavType("rescueHistory");
            }}
            style={
              navType === "rescueHistory" && colors.backgroundColor
                ? {
                    background: colors.backgroundColor,
                    color: "#fff",
                  }
                : navType === "rescueHistory" && !colors.backgroundColor
                ? { background: "#569A59", color: "#fff" }
                : null
            }
          >
            <Upload
              color={
                navType === "rescueHistory"
                  ? "#fff"
                  : colors.backgroundColor || "#569A59"
              }
            />
            Resgates Cashback
          </li>
          <li
            onClick={() => {
              setNavType("settings");
            }}
            style={
              navType === "settings" && colors.backgroundColor
                ? {
                    background: colors.backgroundColor,
                    color: "#fff",
                  }
                : navType === "settings" && !colors.backgroundColor
                ? { background: "#569A59", color: "#fff" }
                : null
            }
          >
            <SettingsIcon
              color={
                navType === "settings"
                  ? "#fff"
                  : colors.backgroundColor || "#569A59"
              }
            />
            Configurações
          </li>
          <li
            onClick={() => {
              logout();
            }}
          >
            <Exit color={colors.backgroundColor} />
            Sair
          </li>
        </ul>
        {navType === "extract" && <ExtractSection extracts={extracts} />}
        {navType === "settings" && <Settings user={user} setUser={setUser} />}
        {navType === "rescueHistory" && (
          <RescueHistory extracts={cashbackHistory} />
        )}
      </div>
      <Footer />
      {loading ? <Loader /> : <></>}
      <Modal open={open} onClose={() => setOpen(false)}>
        <FaqModal setOpen={setOpen} faqType={faqType} />
      </Modal>
      <Modal open={openRecue} onClose={() => setOpenRecue(false)}>
        <RescueModal
          setOpen={setOpenRecue}
          value={approved}
          handleSubmit={rescueSubmit}
        />
      </Modal>
    </>
  );
}
